import { useEffect } from "react";
import { MODULES, ITEMS_FAVORITES_KEY } from "../utils/constants";
import { debugLog } from "../utils/utils";
import { useLocalStorage } from "@uidotdev/usehooks";

/**
 * @typedef {object} FavoritesList
 * @property {string} LocalStorageKey
 * @property {number[]} ItemIdsList
 */

/**
 * @type {FavoritesList}
 */
const ITEM_FAVORITE_SCHEMA = {
    LocalStorageKey: ITEMS_FAVORITES_KEY,
    ItemIdsList: [],
}
const useItemFavorite = () => {

    const [favorites, setFavorites] = useLocalStorage(ITEM_FAVORITE_SCHEMA.LocalStorageKey, ITEM_FAVORITE_SCHEMA.ItemIdsList);


    /**
     * Permite conocer si un item ya se encuentra marcado como favorito
     * @param {number} idItem 
     * @returns {boolean}
     */
    const isBookmarked = (idItem) => {
        return favorites.includes(idItem);
    }

    /**
     * Cambia el estado que indica si un item es favorito o no
     * @param {number} idItem 
     */
    const toggleItemFavorite = (idItem) => {
        debugLog(MODULES.USE_ITEM_FAVORITE, `toggleItemFavorite. IdItem: ${idItem}`)

        const isItemBookmarked = isBookmarked(idItem);
        debugLog(MODULES.USE_ITEM_FAVORITE, `toggleItemFavorite. isItemBookmarked: ${isItemBookmarked}`)
        if (isItemBookmarked) {
            setFavorites((prev) => prev.filter((b) => b !== idItem));
        }
        else setFavorites((prev) => [...prev, idItem]);
    };

    // const toggleItemFavorite2 = (idItem) => () => {
    //     debugLog(MODULES.USE_ITEM_FAVORITE, `toggleItemFavorite. IdItem: ${idItem}`)

    //     const isBookmarked = favorites.includes(idItem);
    //     debugLog(MODULES.USE_ITEM_FAVORITE, `toggleItemFavorite. isBookmarked: ${isBookmarked}`)
    //     if (isBookmarked) {
    //         setFavorites((prev) => prev.filter((b) => b !== idItem));
    //     }
    //     else setFavorites((prev) => [...prev, idItem]);
    // };

    return { favorites, isBookmarked, toggleItemFavorite };

};

export default useItemFavorite
