import { Col, Container, Row } from 'react-bootstrap'
import MyFavourites from '../../components/MyFavourites/MyFavourites'

const MyFavouritesPage = () => {
    return (
        <Container fluid={false} >
            <Row>
                <Col xs={12} md={12}>
                    <MyFavourites />
                </Col>
            </Row>
        </Container>
    )
}
export default MyFavouritesPage