import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getItems } from '../../../services/Items.service'


export const fetchItemsAsync = createAsyncThunk(
    'search/searchApplyFiltersStatus',
    async (filters,thunkApi) => {
       
        const items = await getItems(filters)
        return [items,filters];
    }
)
const searchSlice = createSlice({
    name: 'search',
    initialState: { items: {} ,itemsFilters:{}},
    reducers: {
        
        // searchApplyFilters(state, action) {
        //     //do api call
        //     //store results
        //     getItems()

        //     state.push({
        //         id: action.payload.id,
        //         text: action.payload.text,
        //         completed: false
        //     })
        // },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchItemsAsync.fulfilled,(state,action)=>{
            state.items=action.payload[0]
            state.itemsFilters=action.payload[1]
        })
    }
})

export const { searchApplyFilters } = searchSlice.actions
export default searchSlice.reducer