import { getUserToken } from "./User.service"

const URL_API = process.env.REACT_APP_URL_API


export const getWebScrappingLogs = async (filters) => {

    let url = `${URL_API}/logs?`;
    if (filters)
        url += new URLSearchParams(filters)

    return await fetch(url, {
        method: "GET",
        mode: 'cors',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getUserToken()}`
        }

    })
        .then(response => {
            if (response.status >= 200 && response.status < 300) {
                return response.json()
            } else {
                throw Error(`${response.statusText} ${response.status}`)
            }
        })
        .then(data => {
            return data;

        })
        .catch(error => {
            console.log(error)
            throw error;
        })
}


export const getWebScrappingLogsDetail = async (idWebscrappingLog) => {

    let url = `${URL_API}/logs/${idWebscrappingLog}/detail`;


    return await fetch(url, {
        method: "GET",
        mode: 'cors',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getUserToken()}`
        }

    })
        .then(response => {
            if (response.status >= 200 && response.status < 300) {
                return response.json()
            } else {
                throw Error(`${response.statusText} ${response.status}`)
            }
        })
        .then(data => {
            return data;

        })
        .catch(error => {
            console.log(error)
            throw error;
        })
}