import React, { useEffect, useState } from 'react';
import { Card, Col, Form, Image, Row, Button } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import 'react-toastify/dist/ReactToastify.css';
import { Controller, useForm } from 'react-hook-form';
import ModalNewItem from './ModalNewItem';
import ModalEditItem from './ModalEditItem';
import { getItemsAdmin } from '../../../services/Items.service';
import { useDispatch, useSelector } from 'react-redux';
import { setIsLoading } from '../../../store/features/util/util.slice';


const ItemList = () => {

    const dispatch = useDispatch()

    const { handleSubmit, register, formState: { errors }, control, } = useForm({
        defaultValues: {
            itemName: "",
            itemWeight: "",
            itemWeightUnit: "",
            itemBrand: "",
            itemSpecie: "",
            itemAgeRange: ""
        }
    })

    const [showModalNewItem, setshowModalNewItem] = useState(false)
    const [items, setItems] = useState([])
    const [itemSelected, setItemSelected] = useState(null)
    const [updateItemList, setUpdateItemList] = useState(true);


    const brandList = useSelector(state => state.util.brands)
    const weightUnitList = useSelector(state => state.util.weightUnits)
    const agesRangesList = useSelector(state => state.util.ageRanges)
    const speciesList = useSelector(state => state.util.species)
    const [paginationData, setPaginationData] = useState({ TotalSize: 0, Page: 1, SizePerPage: 10 })



    useEffect(() => {
        if (updateItemList) {
            fetchData(1, 10)
            setUpdateItemList(false);
        }

    }, [updateItemList])

    const fetchData = async (page, sizePerPage, filters = []) => {

        dispatch(setIsLoading(true))
        try {
            const response = await getItemsAdmin({
                page: page, per_page: sizePerPage, ...filters
            })
            setItems(response.data);

            setPaginationData({ TotalSize: response.total, Page: page, SizePerPage: sizePerPage })

        } catch (error) {
            console.log("Ocurrio un error al obtener items", error)
        } finally {
            dispatch(setIsLoading(false))
        }

    }

    const onHandleTableChange = (type, { page, sizePerPage, filters }) => {
        // console.log(filters)
        const filterDto = []
        if (filters) {
            for (const dataField in filters) {
                const dataFieldAux = dataField.indexOf('.') >= 0 ? dataField.split('.')[1] : dataField
                const { filterVal } = filters[dataField];
                filterDto[dataFieldAux] = filterVal

            }
        }

        fetchData(page, sizePerPage, filterDto)
    }


    const columns = [
        {
            dataField: 'imageUrl',
            text: 'Imagen',
            formatter: (cell, row, rowIndex, formatExtraData) => {
                if (row && row.imageUrl) {
                    return (<>
                        <Image
                            src={row.imageUrl}
                            // className="img-item"
                            alt="Imagen de item no disponible"
                            fluid
                            thumbnail
                            width={"50%"}
                        />
                    </>)
                }
            }
        },
        {
            dataField: 'name',
            text: 'Item',
        },
        {
            dataField: 'numberOfAssociatedProducts',
            text: 'Productos asociados',
        },
        {
            dataField: 'itemId',
            text: 'Acciones',
            formatter: (cell, row, rowIndex, formatExtraData) => {
                if (row) {
                    return (<Button size='sm' onClick={() => setItemSelected(row)}>Revisar</Button>)
                }
            }
        }
    ];




    const options = {
        page: paginationData.Page,
        sizePerPage: paginationData.SizePerPage,
        totalSize: paginationData.TotalSize,
        paginationSize: 4,
        pageStartIndex: 1,
        firstPageText: 'Primera',
        prePageText: 'Anterior',
        nextPageText: 'Siguiente',
        lastPageText: 'Última',
        nextPageTitle: 'First page',
        prePageTitle: 'Pre page',
        firstPageTitle: 'Next page',
        lastPageTitle: 'Last page',
        showTotal: false,
        // alwaysShowAllBtns: true, // Always show next and previous button
        // withFirstAndLast: false, // Hide the going to First and Last page button
        // hideSizePerPage: true, // Hide the sizePerPage dropdown always
        // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
        // paginationTotalRenderer: customTotal,
        disablePageTitle: true,
        sizePerPageList: [{
            text: '5', value: 5
        }, {
            text: '10', value: 10
        }] // A numeric array is also available. the purpose of above example is custom the text
    };

    /**
     * Aplica filtros del formulario.
     * Siempre retorna a la primera página.
     * @param {*} data Filtros del formulario
     */
    const onSubmit = (data) => {
        
        let formData = {};


        if (data.itemName)
            Object.assign(formData, { itemName: data.itemName })

        if (data.itemWeight)
            Object.assign(formData, { itemWeight: data.itemWeight })

        if (data.itemWeightUnit)
            Object.assign(formData, { itemWeightUnit: data.itemWeightUnit })

        if (data.itemBrand && data.itemBrand !== "0")
            Object.assign(formData, { itemBrand: data.itemBrand })

        if (data.itemAgeRange && data.itemAgeRange !== "0")
            Object.assign(formData, { itemAgeRange: data.itemAgeRange })

        if (data.itemSpecie && data.itemSpecie !== "0")
            Object.assign(formData, { itemSpecie: data.itemSpecie })

        
        fetchData(1, paginationData.SizePerPage, formData)
    }




    return (
        <>

            <ModalNewItem
                setShow={() => setshowModalNewItem(false)}
                show={showModalNewItem}
                updateParent={() => setUpdateItemList(true)}
                brands={brandList}
                weightUnits={weightUnitList}
                species={speciesList}
                agesRanges={agesRangesList}
            />
            <ModalEditItem
                show={itemSelected ? true : false}
                hideModal={() => setItemSelected(null)}
                updateParent={() => setUpdateItemList(true)}
                currentItem={itemSelected}
                brands={brandList}
                weightUnits={weightUnitList}
                species={speciesList}
                agesRanges={agesRangesList}
            />

            <Row>
                <Col>
                    <Card>
                        <Card.Body>
                            <Card.Title>Filtros</Card.Title>
                            <Form onSubmit={handleSubmit(onSubmit)}>
                                <Row>
                                    <Col xs={12} md={3}>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Nombre</Form.Label>
                                            <Form.Control
                                                type="text"
                                                {
                                                ...register("itemName",
                                                    {
                                                        maxLength: 100
                                                    }
                                                )}
                                            />
                                            {errors.itemName && errors.itemName.type === 'required' && (
                                                <Form.Text className="text-danger">
                                                    Este campo es requerido.
                                                </Form.Text>
                                            )}
                                            {errors.itemName && errors.itemName.type === 'maxLength' && (
                                                <Form.Text className="text-danger">
                                                    Largo máximo 100 caracteres.
                                                </Form.Text>
                                            )}

                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} md={3}>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Especie</Form.Label>
                                            <Controller
                                                control={control}
                                                name="itemSpecie"
                                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                                    <Form.Select
                                                        onChange={onChange}
                                                        onBlur={onBlur}
                                                        value={value}
                                                    >
                                                        <option value={0}>Seleccione especie</option>
                                                        {speciesList && speciesList.map(specie => {
                                                            return (
                                                                <option key={specie.speciesId} value={specie.speciesId}>{specie.species}</option>
                                                            )
                                                        })}
                                                    </Form.Select>
                                                )}
                                            >
                                            </Controller>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} md={3}>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Marca</Form.Label>
                                            <Controller
                                                control={control}
                                                name="itemBrand"
                                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                                    <Form.Select
                                                        onChange={onChange}
                                                        onBlur={onBlur}
                                                        value={value}
                                                    >
                                                        <option value={0}>Seleccione marca</option>
                                                        {brandList && brandList.map(brand => {
                                                            return (
                                                                <option key={brand.brandId} value={brand.brandId}>{brand.name}</option>
                                                            )
                                                        })}
                                                    </Form.Select>
                                                )}
                                            >
                                            </Controller>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} md={3}>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Rango etario</Form.Label>
                                            <Controller
                                                control={control}
                                                name="itemAgeRange"
                                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                                    <Form.Select
                                                        onChange={onChange}
                                                        onBlur={onBlur}
                                                        value={value}
                                                    >
                                                        <option value={0}>Seleccione rango etario</option>
                                                        {agesRangesList && agesRangesList.map(ageRange => {
                                                            return (
                                                                <option key={ageRange.ageRangeId} value={ageRange.ageRangeId}>{ageRange.ageRange}</option>
                                                            )
                                                        })}
                                                    </Form.Select>
                                                )}
                                            >
                                            </Controller>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} md={3}>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Masa</Form.Label>
                                            <Form.Control
                                                type="number"
                                                {...register("itemWeight",
                                                    { required: false }
                                                )}
                                            />
                                            {errors.itemWeight && errors.itemWeight.type === 'required' && (
                                                <Form.Text className="text-danger">
                                                    Este campo es requerido.
                                                </Form.Text>
                                            )}
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} md={3}>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Unidad de masa</Form.Label>
                                            <Form.Control
                                                type="text"
                                                {...register("itemWeightUnit",
                                                    { required: false, maxLength: 10 }
                                                )}
                                            />
                                            {errors.itemWeightUnit && errors.itemWeightUnit.type === 'required' && (
                                                <Form.Text className="text-danger">
                                                    Este campo es requerido.
                                                </Form.Text>
                                            )}
                                            {errors.itemWeightUnit && errors.itemWeightUnit.type === 'maxLength' && (
                                                <Form.Text className="text-danger">
                                                    Largo máximo 10 caracteres.
                                                </Form.Text>
                                            )}
                                        </Form.Group>
                                    </Col>

                                </Row>
                            </Form>
                            <Row>
                                <Col>
                                    <button
                                        className='btn btn-docpup'
                                        onClick={handleSubmit(onSubmit)}>Filtrar
                                    </button>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>

                </Col>
            </Row>

            <Row className='my-3'>
                <Col>
                    <Card>
                        <Card.Body>
                            <Card.Title >
                                Listado de Items
                            </Card.Title>
                            <Row className='my-3'>
                                <Col xs={12}>
                                    <Button onClick={() => setshowModalNewItem(true)}>Nuevo Item</Button>
                                </Col>
                            </Row>

                            <Row className='my-3'>
                                <Col xs={12}>

                                </Col>
                                <Col>
                                    <BootstrapTable
                                        bootstrap4
                                        remote
                                        keyField='itemId'
                                        data={items}
                                        columns={columns}
                                        pagination={paginationFactory(options)}
                                        filter={filterFactory()}
                                        onTableChange={onHandleTableChange}
                                        hover />
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    );
}
export default ItemList;