import React, { useEffect, useState } from 'react';
import { Card, Col, Form, Row, Tab } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import Button from 'react-bootstrap/Button';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { GetProducts, UpdateProduct } from '../../../services/Products.service';


const TabProducts = ({ currentItem, updateParent }) => {


    const [products, setProducts] = useState([])
    const [itemSelected, setItemSelected] = useState(null)
    const [selectedProducts, setSelectedProducts] = useState([])
    const [paginationData, setPaginationData] = useState({ TotalSize: 0, Page: 1, SizePerPage: 5 })

    useEffect(() => {
        fetchData(1, 5)
    }, [])

    useEffect(() => {
        if (currentItem && products) {
            const alreadyAssocietedIds = products.filter(prod => prod.itemId === currentItem.itemId)
                .map(prod => { return prod.productId })
            setSelectedProducts(alreadyAssocietedIds)
        }
    }, [currentItem, products])



    const fetchData = async (page, sizePerPage, filters = []) => {
        const response = await GetProducts({
            page: page, per_page: sizePerPage, ...filters
        })
        setProducts(response.data);

        setPaginationData({ TotalSize: response.total, Page: page, SizePerPage: sizePerPage })
    }

    const onHandleTableChange = (type, { page, sizePerPage, filters }) => {
        console.log(filters)
        const filterDto = []
        if (filters) {
            for (const dataField in filters) {
                const dataFieldAux = dataField.indexOf('.') >= 0 ? dataField.split('.')[1] : dataField
                const { filterVal } = filters[dataField];
                filterDto[dataFieldAux] = filterVal

            }
        }
        console.log(filterDto)

        fetchData(page, sizePerPage, filterDto)
    }

    const onConfirmChanges = async () => {
        console.log(selectedProducts);

        let successOnSome = false;
        let successOnAll = true;
        let errors = "";
        console.log(selectedProducts)
        for (const selectedProductId in selectedProducts) {

            console.log(selectedProducts[selectedProductId])
            let requestData = {
                itemId: currentItem.itemId
            }
            try {
                let updateResult = await UpdateProduct(selectedProducts[selectedProductId], requestData);
                if (!updateResult.success) {
                    successOnAll = false;
                    errors += `${updateResult.message} \n`;
                } else {
                    successOnSome = true;
                }

            } catch (error) {
                errors += `${error.message} \n`;
                successOnAll = false;
            }
        }

        //mensaje a usuario
        if (successOnAll) {
            toast.success("Productos asociados correctamente.")
        } else {
            toast.error("Error al asociar productos: \n" + errors)
        }

        //verificacion de actualizacion requerida de grilla
        if (successOnSome)
            updateParent()

    }

    const onVerDetalleColumnFormater = (cell, row, rowIndex, formatExtraData) => {
        if (row) {
            return (<a href={row.url} rel="noreferrer" target="_blank">Visitar Página</a>)
        }
    }

    const columns = [{
        dataField: 'tittle',
        text: 'Nombre',
        filter: textFilter({ placeholder: "Buscar por nombre", })
    }
        , {
        dataField: 'shop.shopName',
        text: 'Tienda',
        filter: textFilter({ placeholder: "Buscar por tienda", })
    }
        , {
        dataField: 'brand',
        text: 'Marca',
        filter: textFilter({ placeholder: "Buscar por marca", })
    }
        , {
        dataField: 'productId',
        text: 'Acciones',
        formatter: onVerDetalleColumnFormater
    }];

    const selectRow = {
        mode: 'checkbox',
        clickToSelect: true,
        selected: selectedProducts,
        onSelect: (row, isSelect, rowIndex, e) => {
            if (isSelect) {
                let auxArray = [...selectedProducts]
                auxArray.push(row.productId)
                setSelectedProducts(auxArray)
            } else {
                let auxArray = [...selectedProducts]
                auxArray = auxArray.filter(item => item !== row.productId)
                setSelectedProducts(auxArray)
            }
        }
    };

    const options = {
        page: paginationData.Page,
        sizePerPage: paginationData.SizePerPage,
        totalSize: paginationData.TotalSize,
        paginationSize: 4,
        pageStartIndex: 1,
        firstPageText: 'Primera',
        prePageText: 'Anterior',
        nextPageText: 'Siguiente',
        lastPageText: 'Última',
        nextPageTitle: 'First page',
        prePageTitle: 'Pre page',
        firstPageTitle: 'Next page',
        lastPageTitle: 'Last page',
        showTotal: true,
        // alwaysShowAllBtns: true, // Always show next and previous button
        // withFirstAndLast: false, // Hide the going to First and Last page button
        // hideSizePerPage: true, // Hide the sizePerPage dropdown always
        // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
        // paginationTotalRenderer: customTotal,
        disablePageTitle: true,
        sizePerPageList: [{
            text: '5', value: 5
        }, {
            text: '10', value: 10
        }] // A numeric array is also available. the purpose of above example is custom the text
    };
    return (
        <Card>
            <Card.Body>
                <Row>
                    <Col>
                        <BootstrapTable
                            bootstrap4
                            remote
                            keyField='productId'
                            data={products}
                            columns={columns}
                            pagination={paginationFactory(options)}
                            onTableChange={onHandleTableChange}
                            filter={filterFactory()}
                            selectRow={selectRow}
                            hover />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Button onClick={() => onConfirmChanges()}>Confirmar cambios</Button>
                    </Col>
                </Row>

            </Card.Body>
        </Card>
    );
}
export default TabProducts;