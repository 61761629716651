
import React from 'react'
import { Image } from 'react-bootstrap'

const Loader = () => {


    return (
        <>
            <div className='overlay'>
            <div className='loader'></div>
        
            </div>
        </>


    )
}
export default Loader