import React, { useEffect } from 'react';
import { Card, Col, Form, Row, Image } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { UpdateItem } from '../../../services/Items.service'
import { setIsLoading } from '../../../store/features/util/util.slice';

const TabEditItem = ({ currentItem, hideModal, updateParent }) => {

    const dispatch = useDispatch()

    const brands = useSelector(state => state.util.brands)
    const species = useSelector(state => state.util.species)
    const agesRanges = useSelector(state => state.util.ageRanges)
    const weightUnits = useSelector(state => state.util.weightUnits)



    const { register, handleSubmit, formState: { errors }, control, setValue } = useForm();
    const selectedImageUrl = useWatch({
        control,
        name: "urlItem"
    })


    const onSubmit = async (data) => {
        try {
            dispatch(setIsLoading(true))
            const updateResult = await UpdateItem(currentItem.itemId, data)

            if (updateResult.success) {
                updateParent()
                toast.success(updateResult.message)
                hideModal(false)

            } else {
                toast.error(updateResult.message + ": " + JSON.stringify(updateResult.payload))
            }
        } catch (error) {
            toast.error("Ocurruio un error inesperado, intente nuevamente")
        } finally {
            dispatch(setIsLoading(false))
        }
    }


    useEffect(() => {
        if (currentItem) {
            // setValue();
            setValue("itemName", currentItem.name)
            setValue("itemBrandId", currentItem.brand?.brandId)
            setValue("urlItem", currentItem.imageUrl)
            setValue("itemWeight", currentItem.weight)
            setValue("itemWeightUnitId", currentItem.weightUnit?.weightUnitId)
            setValue("itemSpeciesId", currentItem.species?.speciesId)
            setValue("itemAgeRangeId", currentItem.ageRange?.ageRangeId)

        }

    }, [currentItem, setValue])
    return (
        <Card>
            <Card.Body>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Row>
                        <Col md={6}>
                            <Form.Group className="mb-3" >
                                <Form.Label>Nombre</Form.Label>
                                <Form.Control
                                    type="text"
                                    {
                                    ...register("itemName",
                                        {
                                            required: true,
                                            maxLength: 100
                                        }
                                    )}
                                />
                                {errors.itemName && errors.itemName.type === 'required' && (
                                    <Form.Text className="text-danger">
                                        Este campo es requerido.
                                    </Form.Text>
                                )}
                                {errors.itemName && errors.itemName.type === 'maxLength' && (
                                    <Form.Text className="text-danger">
                                        Largo máximo 100 caracteres.
                                    </Form.Text>
                                )}

                            </Form.Group>

                            <Form.Group className="mb-3" >
                                <Form.Label>Marca</Form.Label>
                                <Controller
                                    control={control}
                                    name="itemBrandId"
                                    rules={{
                                        required: true
                                    }}
                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                        <Form.Select
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            value={value}
                                        >
                                            <option>Seleccione marca</option>
                                            {brands && brands.map(brand => {
                                                return (
                                                    <option key={brand.brandId} value={brand.brandId}>{brand.name}</option>
                                                )
                                            })}
                                        </Form.Select>
                                    )}
                                >
                                </Controller>
                                {errors.itemBrand && errors.itemBrand.type === 'required' && (
                                    <Form.Text className="text-danger">
                                        Este campo es requerido.
                                    </Form.Text>
                                )}
                            </Form.Group>

                            <Form.Group className="mb-3" >
                                <Form.Label>Especie</Form.Label>
                                <Controller
                                    control={control}
                                    name="itemSpeciesId"
                                    rules={{
                                        required: true
                                    }}
                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                        <Form.Select
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            value={value}
                                        >
                                            <option>Seleccione</option>
                                            {species && species.map(specie => {
                                                return (
                                                    <option value={specie.speciesId} key={specie.speciesId}>{specie.species}</option>
                                                )
                                            })}
                                        </Form.Select>
                                    )}
                                >
                                </Controller>
                                {errors.itemBrand && errors.itemBrand.type === 'required' && (
                                    <Form.Text className="text-danger">
                                        Este campo es requerido.
                                    </Form.Text>
                                )}
                            </Form.Group>

                            <Form.Group className="mb-3" >
                                <Form.Label>Rango Etario</Form.Label>
                                <Controller
                                    control={control}
                                    name="itemAgeRangeId"
                                    rules={{
                                        required: true
                                    }}
                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                        <Form.Select
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            value={value}
                                        >
                                            <option>Seleccione</option>
                                            {agesRanges && agesRanges.map(ageRange => {
                                                return (
                                                    <option value={ageRange.ageRangeId} key={ageRange.ageRangeId}>{ageRange.ageRange}</option>
                                                )
                                            })}
                                        </Form.Select>
                                    )}
                                >
                                </Controller>
                                {errors.itemBrand && errors.itemBrand.type === 'required' && (
                                    <Form.Text className="text-danger">
                                        Este campo es requerido.
                                    </Form.Text>
                                )}
                            </Form.Group>

                            <Form.Group className="mb-3" >
                                <Form.Label>Masa</Form.Label>
                                <Form.Control
                                    type="number"
                                    {...register("itemWeight",
                                        { required: true }
                                    )}
                                />
                                {errors.itemWeight && errors.itemWeight.type === 'required' && (
                                    <Form.Text className="text-danger">
                                        Este campo es requerido.
                                    </Form.Text>
                                )}
                            </Form.Group>

                            <Form.Group className="mb-3" >
                                <Form.Label>Unidad de masa</Form.Label>
                                <Controller
                                    control={control}
                                    name="itemWeightUnitId"
                                    rules={{
                                        required: true
                                    }}
                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                        <Form.Select
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            value={value}
                                        >
                                            <option>Seleccione</option>
                                            {weightUnits && weightUnits.map(weightUnit => {
                                                return (
                                                    <option key={weightUnit.weightUnitId} value={weightUnit.weightUnitId}>{weightUnit.name}</option>
                                                )
                                            })}
                                        </Form.Select>
                                    )}
                                >
                                </Controller>
                                {errors.itemBrand && errors.itemBrand.type === 'required' && (
                                    <Form.Text className="text-danger">
                                        Este campo es requerido.
                                    </Form.Text>
                                )}
                            </Form.Group>
                        </Col>
                        <Col md={6}>


                            <Form.Group className="mb-3" >
                                <Form.Label>Url Imagen</Form.Label>
                                <Form.Control
                                    type="text"
                                    {...register("urlItem",
                                        {
                                            required: true,
                                            maxLength: 300
                                        }
                                    )}
                                />
                                {errors.urlItem && errors.urlItem.type === 'required' && (
                                    <Form.Text className="text-danger">
                                        Este campo es requerido.
                                    </Form.Text>
                                )}
                                {errors.urlItem && errors.urlItem.type === 'maxLength' && (
                                    <Form.Text className="text-danger">
                                        Largo máximo 300 caracteres.
                                    </Form.Text>
                                )}
                            </Form.Group>
                            {/* Pre visualización de imagen seleccionada */}
                            <Image
                                src={selectedImageUrl}
                                // className="img-item"
                                alt="Imagen de item no disponible"
                                fluid
                                thumbnail
                            />


                        </Col>

                    </Row>
                </Form>
                <Row>

                    <Col>
                        <Button variant="primary" onClick={handleSubmit(onSubmit)}>
                            Actualizar
                        </Button>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    );
}
export default TabEditItem;