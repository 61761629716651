import React, { useEffect, useState } from 'react';
import { Card, Col, Form, Row, Tab,Image } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { GetProductById, UpdateProduct } from '../../../services/Products.service';
import { getItems } from '../../../services/Items.service';
import { useDispatch } from 'react-redux';
import { setIsLoading } from '../../../store/features/util/util.slice';

const TabItems = ({ currentProduct, handleClose }) => {

    const dispatch = useDispatch()

    const [currentProductState, setCurrentProductState] = useState(null)
    const [items, setItems] = useState([])
    const [paginationData, setPaginationData] = useState({ TotalSize: 0, Page: 1, SizePerPage: 5 })

    useEffect(() => {
        fetchData(1, 5)
    }, [])

    useEffect(() => {
        if (currentProduct) {
            getProductToProcess(currentProduct.productId)
        }
    }, [currentProduct])


    const getProductToProcess = async (productId) => {
        const res = await GetProductById(productId)
        setCurrentProductState(res)
    }

    const fetchData = async (page, sizePerPage, filters = []) => {
        dispatch(setIsLoading(true))
        try {
            const response = await getItems({
                page: page, per_page: sizePerPage, ...filters
            })
            setItems(response.data);

            setPaginationData({ TotalSize: response.total, Page: page, SizePerPage: sizePerPage })

        } catch (error) {
            console.log("Ocurrio un error al obtener items", error)
            toast.error("Ocurrio un error al obtener items")

        }finally{
            dispatch(setIsLoading(false))
        }

    }

    const onHandleTableChange = (type, { page, sizePerPage, filters }) => {
        // console.log(filters)
        const filterDto = []
        if (filters) {
            for (const dataField in filters) {
                const dataFieldAux = dataField.indexOf('.') >= 0 ? dataField.split('.')[1] : dataField
                const { filterVal } = filters[dataField];
                filterDto[dataFieldAux] = filterVal

            }
        }
        console.log(filterDto)

        fetchData(page, sizePerPage, filterDto)
    }


    const AssignItem = async (itemId) => {
        let requestData = {
            itemId: itemId
        }
        try {
            dispatch(setIsLoading(true))
            let updateResult = await UpdateProduct(currentProductState.productId, requestData);
            if (!updateResult.success) {
                toast.error(updateResult.message)
            } else {
                toast.success(updateResult.message)
                handleClose()                
            }

        } catch (error) {
            console.log("error al asignar item", error)
            toast.error("Ocurrio un error al asignar item.")
        }finally{
            dispatch(setIsLoading(false))
        }
    }



    const columns = [
        {
            dataField: 'imageUrl',
            text: 'Imagen',
            formatter: (cell, row, rowIndex, formatExtraData) => {
                if (row && row.imageUrl) {
                    return (
                        <>
                            <Image
                                src={row.imageUrl}
                                // className="img-item"
                                alt="Imagen no disponible"
                                fluid
                                thumbnail
                                width={"50%"}
                            />
                        </>)
                }
            }
        },
        {
            //Es diferente al campo utilizado para mostrar, ya que el nombre
            //del filtro enviado se toma en base a este
            dataField: 'itemName',
            text: 'Item',
            formatter: (cell, row, rowIndex, formatExtraData) => {
                if (row) {
                    return row.name
                }
            },
            filter: textFilter({ placeholder: "Buscar por nombre", })
        },
        {
            dataField: 'weightWithUnitSymbol',
            text: 'Masa',
            filter: textFilter({ placeholder: "Buscar por masa", })
        },
        {
            dataField: 'brandName',
            text: 'Marca',
            filter: textFilter({ placeholder: "Buscar por marca", })
        },
        {
            dataField: 'numberOfAssociatedProducts',
            text: 'Productos asociados',
            formatter: (cell, row, rowIndex, formatExtraData) => {
                if (row) {
                    return row?.numberOfAssociatedProducts
                }
            }
        },
        {
            dataField: 'itemId',
            text: 'Acciones',
            formatter: (cell, row, rowIndex, formatExtraData) => {
                if (row) {
                    if (row.itemId === currentProductState.itemId)
                        return (<button onClick={() => AssignItem(null)}>Des-Asignar</button>)
                    else
                        return (<button onClick={() => AssignItem(row.itemId)}>Asignar</button>)
                }
            }
        }];


    const options = {
        page: paginationData.Page,
        sizePerPage: paginationData.SizePerPage,
        totalSize: paginationData.TotalSize,
        paginationSize: 4,
        pageStartIndex: 1,
        firstPageText: 'Primera',
        prePageText: 'Anterior',
        nextPageText: 'Siguiente',
        lastPageText: 'Última',
        nextPageTitle: 'First page',
        prePageTitle: 'Pre page',
        firstPageTitle: 'Next page',
        lastPageTitle: 'Last page',
        showTotal: true,
        // alwaysShowAllBtns: true, // Always show next and previous button
        // withFirstAndLast: false, // Hide the going to First and Last page button
        // hideSizePerPage: true, // Hide the sizePerPage dropdown always
        // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
        // paginationTotalRenderer: customTotal,
        disablePageTitle: true,
        sizePerPageList: [{
            text: '5', value: 5
        }, {
            text: '10', value: 10
        }] // A numeric array is also available. the purpose of above example is custom the text
    };
    return (
        <Card>
            <Card.Body>
                <Row>
                    <Col>
                        {currentProductState && (<BootstrapTable
                            bootstrap4
                            remote
                            keyField='itemId'
                            data={items}
                            columns={columns}
                            pagination={paginationFactory(options)}
                            onTableChange={onHandleTableChange}
                            filter={filterFactory()}
                            hover />)}

                    </Col>
                </Row>
                <Row>
                    <Col>
                        {/* <Button onClick={() => onConfirmChanges()}>Confirmar cambios</Button> */}
                    </Col>
                </Row>

            </Card.Body>
        </Card>
    );
}
export default TabItems;