
import React, { useMemo } from 'react'
import { Pagination } from 'react-bootstrap';

const CustomPagination = ({ currentPage, pageSize, totalCount, siblingCount = 1, onPageChange }) => {

    const DOTS = "DOTS";

    const range = (start, end) => {
        let length = end - start + 1;
        /*
            Create an array of certain length and set the elements within it from
          start value to end value.
        */
        return Array.from({ length }, (_, idx) => idx + start);
    };

    const paginationRange = useMemo(() => {
        /**
         * Total page count is less than the page pills we want to show. In such a case we just return the range from 1 to totalPageCount.
        */
        const totalPageCount = Math.ceil(totalCount / pageSize);

        // Pages count is determined as siblingCount + firstPage + lastPage + currentPage + 2*DOTS
        const totalPageNumbers = siblingCount + 5;


        /*
          Case 1:
          If the number of pages is less than the page numbers we want to show in our
          paginationComponent, we return the range [1..totalPageCount]
        */
        if (totalPageNumbers >= totalPageCount) {
            return range(1, totalPageCount);
        }

        /*
       Calculate left and right sibling index and make sure they are within range 1 and totalPageCount
   */
        const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
        const rightSiblingIndex = Math.min(
            currentPage + siblingCount,
            totalPageCount
        );

        /*
     We do not show dots just when there is just one page number to be inserted between the extremes of sibling and the page limits i.e 1 and totalPageCount. Hence we are using leftSiblingIndex > 2 and rightSiblingIndex < totalPageCount - 2
   */
        const shouldShowLeftDots = leftSiblingIndex > 2;
        const shouldShowRightDots = rightSiblingIndex < totalPageCount - 2;

        const firstPageIndex = 1;
        const lastPageIndex = totalPageCount;

        /*
               Case 2: No left dots to show, but rights dots to be shown
           */
        if (!shouldShowLeftDots && shouldShowRightDots) {
            let leftItemCount = 3 + 2 * siblingCount;
            let leftRange = range(1, leftItemCount);

            return [...leftRange, DOTS, totalPageCount];
        }

        /*
       Case 3: No right dots to show, but left dots to be shown
   */
        if (shouldShowLeftDots && !shouldShowRightDots) {

            let rightItemCount = 3 + 2 * siblingCount;
            let rightRange = range(
                totalPageCount - rightItemCount + 1,
                totalPageCount
            );
            return [firstPageIndex, DOTS, ...rightRange];
        }
        /*
             Case 4: Both left and right dots to be shown
         */
        if (shouldShowLeftDots && shouldShowRightDots) {
            let middleRange = range(leftSiblingIndex, rightSiblingIndex);
            return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex];
        }


    }, [currentPage, pageSize, totalCount])


    const calculateLastPageIndex = () => {
        return Math.ceil(totalCount / pageSize);
    }

    return (
        <>
            <Pagination className='claymorphism-item'>
                <Pagination.First onClick={() => onPageChange(1)} />
                <Pagination.Prev disabled={currentPage === 1} onClick={() => onPageChange(currentPage - 1)} />

                {paginationRange?.map(pageNumber => {
                    if (pageNumber === DOTS) {
                        return (<Pagination.Ellipsis />)
                    }
                    return (
                        <Pagination.Item
                            key={pageNumber}
                            onClick={() => onPageChange(pageNumber)}
                            active={currentPage === pageNumber}
                        >
                            {pageNumber}
                        </Pagination.Item>
                    )
                })}


                <Pagination.Next onClick={() => onPageChange(currentPage + 1)} />
                <Pagination.Last onClick={() => onPageChange(calculateLastPageIndex())} />
            </Pagination>
        </>


    )
}
export default CustomPagination