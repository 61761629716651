const URL_API = process.env.REACT_APP_URL_API


export const GetBrands = async () => {
    return await fetch(`${URL_API}/brands`, {
        method: "GET",
        mode: 'cors',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    })
        .then(response => {
            if (response.status >= 200 && response.status < 300) {
                return response.json()
            } else {
                throw Error(`${response.statusText} ${response.status}`)
            }
        })
        .then(data => {
            return data;

        })
        .catch(error => {
            console.log(error)
            throw error;
        })
}


export const GetWeightUnits = async () => {
    return await fetch(`${URL_API}/weightUnits`, {
        method: "GET",
        mode: 'cors',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    })
        .then(response => {
            if (response.status >= 200 && response.status < 300) {
                return response.json()
            } else {
                throw Error(`${response.statusText} ${response.status}`)
            }
        })
        .then(data => {
            return data;

        })
        .catch(error => {
            console.log(error)
            throw error;
        })
}

export const GetAgesRanges = async () => {
    return await fetch(`${URL_API}/agesRanges`, {
        method: "GET",
        mode: 'cors',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    })
        .then(response => {
            if (response.status >= 200 && response.status < 300) {
                return response.json()
            } else {
                throw Error(`${response.statusText} ${response.status}`)
            }
        })
        .then(data => {
            return data;

        })
        .catch(error => {
            console.log(error)
            throw error;
        })
}

export const GetSpecies = async () => {
    return await fetch(`${URL_API}/species`, {
        method: "GET",
        mode: 'cors',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    })
        .then(response => {
            if (response.status >= 200 && response.status < 300) {
                return response.json()
            } else {
                throw Error(`${response.statusText} ${response.status}`)
            }
        })
        .then(data => {
            return data;

        })
        .catch(error => {
            console.log(error)
            throw error;
        })
}

export const GetShops = async () => {
    return await fetch(`${URL_API}/shops`, {
        method: "GET",
        mode: 'cors',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    })
        .then(response => {
            if (response.status >= 200 && response.status < 300) {
                return response.json()
            } else {
                throw Error(`${response.statusText} ${response.status}`)
            }
        })
        .then(data => {
            return data;

        })
        .catch(error => {
            console.log(error)
            throw error;
        })
}
