import { useState, useEffect } from "react";
import { Button, Card, Col, Container, Row, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { getItemById } from "../../services/Items.service";
import { setIsLoading } from "../../store/features/util/util.slice"
import "./ItemView.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import FavoriteButtonIcon from "../Shared/FavoriteButtonIcon";
import { toast } from "react-toastify";

const ItemView = () => {

    let { productId } = useParams();
    const dispatch = useDispatch()
    const isLoading = useSelector(state => state.util.isLoading)


    const [productInfo, setProductInfo] = useState(null);
    const [productNotFound, setProductNotFound] = useState(false)

    useEffect(() => {
        const fetchData = async () => {
            dispatch(setIsLoading(true))
            try {
                const item = await getItemById(productId)
                if (!item) {
                    setProductNotFound(true)
                } else {
                    item.shops?.sort(orderByPrice)
                    setProductInfo(item)
                }


            } catch (error) {
                console.log(error)
                toast.error("Error al consultar producto")
            } finally {
                dispatch(setIsLoading(false))
            }
        }

        if (productId) {
            setProductNotFound(false)
            setProductInfo(null)
            fetchData()
        }

    }, [productId])

    const orderByPrice = (a, b) => {
        if (a.currentPrice < b.currentPrice) {
            return -1;
        }
        if (a.currentPrice > b.currentPrice) {
            return 1;
        }
        // a must be equal to b
        return 0;
    }

    const productPresentation = (productInfo) => {
        if (productInfo) {
            return `${productInfo?.weight} ${productInfo?.weightUnit?.symbol}`
        }
        return ""

    }
    return (

        <Container >
            <Row className="my-4">
                <Col>
                    <Link to="/" className="btn btn-docpup btn-sm">
                        <FontAwesomeIcon icon={faArrowLeft} /> Volver
                    </Link>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card className="claymorphism-item item-view mb-3">
                        {isLoading ?
                            (
                                <Card.Body className="item-view-empty">
                                    <Card.Title className="text-center my-3">
                                        Cargando ...
                                    </Card.Title>
                                </Card.Body>
                            )
                            :
                            (
                                <>
                                    {productInfo ?
                                        (
                                            <Card.Body >
                                                <Card.Title className="text-center my-3">
                                                    <FavoriteButtonIcon itemId={productId} />
                                                    {" "}
                                                    {productInfo?.name}
                                                </Card.Title>
                                                <Row>
                                                    <Col md={6} className="text-center my-2">
                                                        <img
                                                            src={productInfo?.imageUrl}
                                                            className="img-item"
                                                            alt={productInfo?.name}>

                                                        </img>
                                                    </Col>
                                                    <Col md={6} className="my-2">
                                                        <strong>Detalles</strong>
                                                        <p>Marca: {productInfo?.brand?.brandName}</p>
                                                        <p>Presentación:{productPresentation(productInfo)} </p>
                                                    </Col>
                                                </Row>
                                                <Row className="justify-content-center text-center mt-3">
                                                    <Col md={6}>
                                                        <Table
                                                            hover
                                                            responsive
                                                        >
                                                            <thead>
                                                                <tr>
                                                                    <th>Tienda</th>
                                                                    <th>Precio</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {productInfo?.shops.length > 0 ?
                                                                    (productInfo?.shops?.map(product => {
                                                                        return (
                                                                            <tr key={product.shop.shopId}>
                                                                                <td>{product.shop.shopName}</td>

                                                                                <td className="link-to-shop">
                                                                                    <a href={product.url} target="_blank">
                                                                                        {`$ ${new Intl.NumberFormat('es-CL').format(product.currentPrice)}`}
                                                                                    </a></td>
                                                                            </tr>

                                                                        )
                                                                    })
                                                                    ) :
                                                                    (
                                                                        <tr>
                                                                            <td colSpan={2}>
                                                                                Sin información
                                                                            </td>
                                                                        </tr>
                                                                    )}
                                                            </tbody>
                                                        </Table>

                                                    </Col>
                                                </Row>
                                            </Card.Body>
                                        ) :
                                        (
                                            <Card.Body className="item-view-empty">
                                                <Card.Title className="text-center my-3">
                                                    Producto no encontrado
                                                </Card.Title>
                                            </Card.Body>
                                        )}
                                </>
                            )
                        }


                    </Card>
                </Col>
            </Row>


        </Container >

    )
}
export default ItemView