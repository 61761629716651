import './App.scss';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import AppRoutes from './routes/AppRoutes';

function App() {
  return (
    <div className="App">
      <ToastContainer />
      <AppRoutes/>
    </div>
  );
}

export default App;
