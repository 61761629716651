import React from 'react';
import { Col, Row, Tab, Tabs } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import 'react-toastify/dist/ReactToastify.css';
import TabEditProduct from './TabEditProduct';
import TabItems from './TabItems';

const ModalAssignItem = ({ show, setShow, updateParent, currentProduct }) => {
    const handleClose = () => setShow(false);
    return (
        <>

            <Modal show={show} onHide={handleClose} size="xl">
                <Modal.Header closeButton>
                    <Row>
                        <Col xs={12}><Modal.Title>{currentProduct?.itemId ? 'Modificacion de item asignado' : 'Asignación de item'}</Modal.Title></Col>
                        <Col xs={12}>Item: {currentProduct?.tittle}</Col>
                        <Col xs={12}>Marca: {currentProduct?.brand}</Col>
                    </Row>
                </Modal.Header>
                <Modal.Body>
                    <Tabs defaultActiveKey="items">
                        <Tab title="Items" eventKey='items'>
                            <TabItems currentProduct={currentProduct} handleClose={() => handleClose()} />
                        </Tab>
                        <Tab title="Editar producto" eventKey='producto' >
                            "En desarrollo"
                            {/* <TabEditProduct currentProduct={currentProduct} setShow={() => setShow()} updateParent={() => updateParent()} /> */}
                        </Tab>

                    </Tabs>


                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Cerrar
                    </Button>

                </Modal.Footer>
            </Modal>
        </>
    );
}
export default ModalAssignItem;