import { configureStore } from '@reduxjs/toolkit'
import searchReducer from './features/search/search.slice'
import utilReducer from './features/util/util.slice'

export default configureStore({
    reducer: {
        search: searchReducer,
        util: utilReducer

    }
})