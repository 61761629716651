import { useEffect } from "react";
import { MODULES, SESSION_KEY } from "../utils/constants";
import { GetLogger } from "../utils/utils";
import { jwtDecode } from "jwt-decode"
import moment from "moment";
import { useSessionStorage } from "@uidotdev/usehooks";

const customLogger = GetLogger(MODULES.USE_SESSION_HOOK)

export const useSession = () => {

    // const [session, setSessionState] = useState(null);
    const [session, setSessionState] = useSessionStorage(SESSION_KEY, null);

    useEffect(() => {
        customLogger("useEffect - current session value", session)

        // if (!session) {
        //     // search in local storage
        //     // // let localSession = localStorage.getItem(SESSION_KEY)

        //     //if not set do nothing
        //     if (!localSession) {
        //         customLogger( "useEffect - session dont exist")
        //         return
        //     }

        //     // if set, validate
        //     const objLocalSession = JSON.parse(localSession)
        //     customLogger( "useEffect -  local storage session value", objLocalSession)
        //     //if set, check token lifetime
        //     if (isTokenExpired(objLocalSession.token)) {
        //         customLogger( "useEffect - token expired")
        //         localStorage.removeItem(SESSION_KEY)
        //         return
        //     }

        //     //if token still vigent, set session
        //     customLogger( "useEffect -  setting existing value")
        //     setSessionState(objLocalSession)
        // }
        if (session) {

            customLogger("useEffect -  found session")
            if (isTokenExpired(session.token)) {
                customLogger("useEffect - token expired")
                setSessionState(null)
                // localStorage.removeItem(SESSION_KEY)
                // return
            } else {
                localStorage.setItem(SESSION_KEY, JSON.stringify(session))
                customLogger("useEffect - token still vigent")
            }
        }
        customLogger("useEffect -  end")
    }, [session]);

    const setSession = (sessionData) => {
        customLogger("setSession", sessionData)
        localStorage.setItem(SESSION_KEY, JSON.stringify(sessionData))
        setSessionState(sessionData)
    }
    const logout = () => {
        customLogger("logout")
        // localStorage.clear()
        setSessionState(null)
    }


    const isTokenExpired = (access_token) => {
        try {
            const decoded = jwtDecode(access_token)
            // customLogger( `Decoded JWT`,decoded)
            const fechaExpiracion = new Date(0)
            fechaExpiracion.setUTCSeconds(decoded.exp)
            const fechaExpMoment = moment(fechaExpiracion)
            const fechaActual = moment()
            const diferencia = fechaExpMoment.diff(fechaActual, 'seconds')

            if (diferencia < 0) {
                customLogger("token is expired")
                return true;
            }
            else {

                return false;
            }
        }
        catch (error) {
            console.log(error)
            return true;
        }
    }


    return { session, setSession, logout };
};

/*

import { sessionService } from 'redux-react-session'
import { loginUser, updateToken, logoutUser } from '../../services/apiAuthService'



export const login = (user, tokenCaptcha) => {
    return () => {
        return loginUser(user, tokenCaptcha).then(response => {
            if (!response.error) {
                const { access_token, userName, deviceId, refresh_token, perfilId, clienteId } = response
                // console.log(response)

                sessionService.saveSession({ access_token, deviceId, refresh_token })
                sessionService.saveUser({ userName, recordoCuenta: user.recordarCuenta, perfilId, clienteId })

                return true
            }
            else {
                return false
            }
        }).catch(function (reason) {
            console.log(reason)
            return false
        })
    }
}

export const logout = () => {
    return async (dispatch, getState) => {
        const user = await sessionService.loadUser()
        const session = await sessionService.loadSession()

        if (user.recordoCuenta) {
            logoutUser(session.deviceId)
        }

        sessionService.deleteSession();
        sessionService.deleteUser();
    };
};

export const userLoggedOut = () => {
    return {
        type: 'RESET_STORE',
    }
}

export const validateSession = async (session) => {
    const diferencia = validateTokenLifetime(session);

    if (diferencia < 0) {
        return await getNewToken(session) != null
    }
    else {
        return true
    }
}

const validateTokenLifetime = (session) => {
    try {
        const { access_token } = session
        const decoded = jwt_decode(access_token)
        const fechaExpiracion = new Date(0)
        fechaExpiracion.setUTCSeconds(decoded.exp)
        const fechaExpMoment = moment(fechaExpiracion)
        const fechaActual = moment()
        const diferencia = fechaExpMoment.diff(fechaActual, 'seconds')

        return diferencia
    }
    catch (error) {
        console.log(error)
        return true
    }
}

const getNewToken = async (session) => {
    const { refresh_token, deviceId } = session

    if (refresh_token && deviceId) {

        return updateToken({ refreshToken: refresh_token, deviceId: deviceId }).then(response => {
            sessionService.saveSession({ access_token: response.access_token, deviceId, refresh_token })

            return response.access_token
        }).catch(function (reason) {
            return null
        })
    }
    else {
        return null
    }
}

export const getToken = async () => {
    const session = await sessionService.loadSession()
    const diferencia = validateTokenLifetime(session)

    if (diferencia < 0) {
        return getNewToken(session)
    }
    else {
        return session.access_token
    }
}

*/