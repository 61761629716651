import { MODULES } from "./constants";

export const LOG_CONFIG = [
    {
        ModuleName: MODULES.USE_SESSION_HOOK,
        IsEnable: false
    },
    {
        ModuleName: MODULES.ROUTE_CONFIG,
        IsEnable: false
    },
    {
        ModuleName: MODULES.HEADER_PAGE,
        IsEnable: false
    },
    {
        ModuleName: MODULES.FAVOURITES_PAGE,
        IsEnable: false
    }
]

export const formatNumber = (number) => {

    let value = new Intl.NumberFormat('es-CL').format(number);
    return value;

}

//TODO - generar metodo para logger en base al módulo
export const GetLogger = (moduleName) => {
    Object.keys(MODULES)
    const myLogger = (message, params = undefined) => debugLog(moduleName, message, params)
    return myLogger
}

export const debugLog = (module, message, params) => {
    const logConfigForModule = LOG_CONFIG.find(x => x.ModuleName === module)
    if (logConfigForModule && logConfigForModule.IsEnable) {
        if (params !== undefined) {
            console.log(`${module} - ${message}`, params)
        } else {

            console.log(`${module} - ${message}`)
        }
    }
}

