import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory from 'react-bootstrap-table2-filter';
import dayjs from 'dayjs';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch } from 'react-redux';
import { setIsLoading } from '../../../store/features/util/util.slice';
import { Controller, useForm } from 'react-hook-form';
import { getWebScrappingLogs } from '../../../services/WebScrappingLogs.service';
import ModalLogsDetail from './ModalLogsDetail';


const WebScrappingLogsList = () => {

    const dispatch = useDispatch()


    const { handleSubmit, register, formState: { errors }, control, } = useForm({
        defaultValues: {
            processUUID: "",
        }
    })
    const [webScrappingLogSelected, setWebScrappingLogSelected] = useState(null)

    const [webScrappingLogs, setWebScrappingLogs] = useState([])
    const [paginationData, setPaginationData] = useState({ TotalSize: 0, Page: 1, SizePerPage: 10 })



    useEffect(() => {
        fetchData(1, 10)
    }, [])

    const fetchData = async (page, sizePerPage, filters = []) => {

        dispatch(setIsLoading(true))
        try {

            const response = await getWebScrappingLogs({
                page: page, per_page: sizePerPage, ...filters
            })
            setWebScrappingLogs(response.data);

            setPaginationData({ TotalSize: response.total, Page: page, SizePerPage: sizePerPage })

        } catch (error) {
            console.log("Ocurrio un error al obtener registros", error)
        } finally {
            dispatch(setIsLoading(false))
        }

    }

    const onHandleTableChange = (type, { page, sizePerPage, filters }) => {
        // console.log(filters)
        const filterDto = []
        if (filters) {
            for (const dataField in filters) {
                const dataFieldAux = dataField.indexOf('.') >= 0 ? dataField.split('.')[1] : dataField
                const { filterVal } = filters[dataField];
                filterDto[dataFieldAux] = filterVal

            }
        }

        fetchData(page, sizePerPage, filterDto)
    }


    const columns = [
        // {
        //     dataField: 'processUUID',
        //     text: 'UUID Proceso',
        // },
        {
            dataField: 'web_scrapping_log_state.webScrappingLogStateName',
            text: 'Estado',
        },
        {
            dataField: 'message',
            text: 'Mensaje',
        },
        {
            dataField: 'web_scrapping_trigger.webScrappingTriggerName',
            text: 'Trigger',
        },

        {
            dataField: 'startDatetime',
            text: 'Fecha inicio',
        },
        {
            dataField: 'updateDatetime',
            text: 'Fecha actualización',
        },
        {
            dataField: 'enlapsedTime',
            text: 'Duración',
            formatter: (cell, row, rowIndex, formatExtraData) => {
                const date = dayjs('2023-05-01').format('dddd, MMMM D, YYYY'); // Monday, May 1, 2023
                const time = dayjs().format('HH:mm:ss'); //09:50:23
                const unixDate = dayjs(1651382400000).format('MM/DD/YY'); // 05/01/22


                if (row && row.updateDatetime) {
                    const enlapsed = dayjs(row.updateDatetime).diff(dayjs(row.startDatetime), "seconds")
                    return `${enlapsed} segundos`

                } else {
                    return ""
                }
            }
        },
        {
            dataField: 'webScrappingLogId',
            text: 'Acciones',
            formatter: (cell, row, rowIndex, formatExtraData) => {
                if (row) {
                    return (
                        <Button
                            size='sm'
                            onClick={() => setWebScrappingLogSelected(row.webScrappingLogId)}
                        >
                            Ver detalle
                        </Button>
                    )
                }
            }
        }
    ];




    const options = {
        page: paginationData.Page,
        sizePerPage: paginationData.SizePerPage,
        totalSize: paginationData.TotalSize,
        paginationSize: 4,
        pageStartIndex: 1,
        firstPageText: 'Primera',
        prePageText: 'Anterior',
        nextPageText: 'Siguiente',
        lastPageText: 'Última',
        nextPageTitle: 'First page',
        prePageTitle: 'Pre page',
        firstPageTitle: 'Next page',
        lastPageTitle: 'Last page',
        showTotal: false,
        // alwaysShowAllBtns: true, // Always show next and previous button
        // withFirstAndLast: false, // Hide the going to First and Last page button
        // hideSizePerPage: true, // Hide the sizePerPage dropdown always
        // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
        // paginationTotalRenderer: customTotal,
        disablePageTitle: true,
        sizePerPageList: [{
            text: '5', value: 5
        }, {
            text: '10', value: 10
        }] // A numeric array is also available. the purpose of above example is custom the text
    };

    const onSubmit = (data) => {
        let formData = {};

        if (data.processUUID)
            Object.assign(formData, { processUUID: data.processUUID })

        fetchData(1, paginationData.SizePerPage, formData)
    }


    return (
        <>
            <ModalLogsDetail
                show={webScrappingLogSelected ? true : false}
                hideModal={() => setWebScrappingLogSelected(null)}
                webScrappingLogId={webScrappingLogSelected}
            />

            <Row>
                <Col>
                    <Card>
                        <Card.Body>
                            <Card.Title>Filtros</Card.Title>
                            <Form onSubmit={handleSubmit(onSubmit)}>
                                <Row>
                                    <Col xs={12} md={3}>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>UUID Proceso</Form.Label>
                                            <Form.Control
                                                type="text"
                                                {
                                                ...register("processUUID",
                                                    {
                                                        maxLength: 40
                                                    }
                                                )}
                                            />
                                            {errors.processUUID && errors.processUUID.type === 'maxLength' && (
                                                <Form.Text className="text-danger">
                                                    Largo máximo 100 caracteres.
                                                </Form.Text>
                                            )}

                                        </Form.Group>
                                    </Col>

                                </Row>
                            </Form>
                            <Row>
                                <Col>
                                    <button
                                        className='btn btn-docpup'
                                        onClick={handleSubmit(onSubmit)}>Filtrar
                                    </button>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>

                </Col>
            </Row>

            <Row className='my-3'>
                <Col>
                    <Card>
                        <Card.Body>
                            <Card.Title>
                                Registros
                            </Card.Title>
                            <br></br>
                            <Row className='my-3'>
                                <Col xs={12}>

                                </Col>
                                <Col>
                                    <BootstrapTable
                                        bootstrap4
                                        remote
                                        keyField='webScrappingLogId'
                                        data={webScrappingLogs}
                                        columns={columns}
                                        pagination={paginationFactory(options)}
                                        filter={filterFactory()}
                                        onTableChange={onHandleTableChange}
                                        hover />
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    );
}
export default WebScrappingLogsList;