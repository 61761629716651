import React from 'react';
import { Col, Image, Row, Tab, Tabs } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { setIsLoading } from '../../../store/features/util/util.slice';
import { DeleteProductById } from '../../../services/Products.service';



const ModalDeleteProduct = ({ show, setShow, updateParent, currentProduct }) => {

    const dispatch = useDispatch()


    const handleClose = () => setShow(false);

    const handleDelete = async () => {
        dispatch(setIsLoading(true))
        try {
            const response = await DeleteProductById(currentProduct.productId)
            if (response.success) {
                toast.success(response.message)
                updateParent()
            } else {
                toast.error(response.message + ": " + JSON.stringify(response.payload))
            }
        } catch (error) {
            console.log("Ocurrio un error inesperado", error)
            toast.error("Ocurrio un error inesperado")
        }
        finally {
            dispatch(setIsLoading(false))
            handleClose()
        }
    }

    return (
        <>
            <Modal show={show} onHide={handleClose} >
                <Modal.Header closeButton>
                    <Row>
                        <Col xs={12}>
                            <Modal.Title>
                                Eliminar producto
                            </Modal.Title>
                        </Col>
                    </Row>
                </Modal.Header>
                <Modal.Body>
                    <p>Confirme que desea eliminar el producto seleccionado. Esta acción no se puede deshacer</p>
                    {currentProduct && (
                        <>
                            {/* {JSON.stringify(currentProduct)} */}
                            < p > Nombre: {currentProduct.tittle}</p>
                            <p>Marca: {currentProduct.brand}</p>
                            <p>Tienda: {currentProduct.shop.shopName}</p>
                            <p>Tiene item asociado: {currentProduct.itemId ? "Si" : "No"}</p>

                            <Image
                                src={currentProduct.urlImage}
                                // className="img-item"
                                alt="Imagen no disponible"
                                fluid
                                thumbnail
                                width={"50%"}
                            />
                        </>
                    )}

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Cancelar
                    </Button>
                    <Button variant="danger" onClick={handleDelete}>
                        Eliminar
                    </Button>
                </Modal.Footer>
            </Modal >
        </>
    );
}
export default ModalDeleteProduct;