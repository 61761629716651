import { Card, Col, Container, Form, Row } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { useSession } from '../hooks/useSession'
import { useEffect } from 'react'
import { Navigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { LoginUser } from '../services/User.service'
import { useDispatch } from 'react-redux'
import { setIsLoading } from '../store/features/util/util.slice'

const LoginPage = () => {
    const { session, setSession } = useSession()

    const dispatch = useDispatch()

    const { handleSubmit, register, formState: { errors } } = useForm({
        defaultValues: {
            username: null,
            password: null,
        }
    })

    const onSubmit = async (data) => {
        dispatch(setIsLoading(true))
        try {
            const res = await LoginUser({ userName: data.username, password: data.password })

            if (res && res.accessToken) {
                setSession({ token: res.accessToken, user: data.username })

            } else if (res && res.message) {
                toast.error(res.message)
            }
            else {
                toast.error("Credenciales incorrectas")
            }

        } catch (error) {
            toast.error(`Ocurrio un error inesperado, intente nuevamente, mas tarde `)
        }finally{
            dispatch(setIsLoading(false))
        }
    }

    return (
        <Container fluid={true} >
            {session ? (<Navigate to={"/admin/items"} />) : ""}

            <Row className='my-3'>
                <Col className='d-flex justify-content-center'>
                    <Card className='text-center px-5 py-5'>
                        <Card.Title>
                            Iniciar Sesión
                        </Card.Title>
                        <Card.Body>
                            <Form onSubmit={handleSubmit(onSubmit)}>
                                <Form.Group className="mb-3" >
                                    <Form.Label>Usuario</Form.Label>
                                    <Form.Control
                                        type="text"
                                        {
                                        ...register("username", { required: true })}
                                    />
                                    {errors.username && errors.username.type === 'required' && (
                                        <Form.Text className="text-danger">
                                            Este campo es requerido.
                                        </Form.Text>
                                    )}
                                </Form.Group>
                                <Form.Group className="mb-3" >
                                    <Form.Label>Contraseña</Form.Label>
                                    <Form.Control
                                        type="password"
                                        {
                                        ...register("password", { required: true })
                                        }
                                    />
                                    {errors.password && errors.password.type === 'required' && (
                                        <Form.Text className="text-danger">
                                            Este campo es requerido.
                                        </Form.Text>
                                    )}
                                </Form.Group>
                                <button
                                    className='btn btn-docpup'
                                    onClick={handleSubmit(onSubmit)}>Login
                                </button>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}
export default LoginPage