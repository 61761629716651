import { SESSION_KEY } from '../utils/constants'

const URL_API = process.env.REACT_APP_URL_API

export const getUserToken = () => {
    const userJson = localStorage.getItem(SESSION_KEY)
    if (userJson) {
        const user = JSON.parse(userJson)
        return user.token
    }
    return null

    // localStorage.setItem(SESSION_KEY, JSON.stringify(sessionData))
}
export const LoginUser = async ({ userName, password }) => {
    return await fetch(`${URL_API}/token`, {
        method: "POST",
        mode: 'cors',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',

        },
        body: JSON.stringify({ userName, password })
    })
        .then(response => {
            if (response.status >= 200 && response.status < 300) {
                return response.json()
            }
            else if (response.status === 401) {
                return response.json()
            }
            else {
                throw Error(`${response.statusText} ${response.status}`)
            }
        })
        .then(data => {
            return data;

        })
        .catch(error => {
            console.log(error)
            throw error;
        })
}
