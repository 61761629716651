import { HashRouter as Router, Route, Routes, useNavigate, Navigate } from 'react-router-dom'
import { useEffect } from 'react';
import { MODULES } from '../utils/constants';
import { GetLogger } from '../utils/utils';
import { useSession } from '../hooks/useSession';
import LayoutPage from '../pages/template/Layout.page'
import { RoutesConfig, RoutesConfigFlat } from './AppRoutesConfig';

const customLogger = GetLogger(MODULES.ROUTE_CONFIG)

const privateRoutes = RoutesConfigFlat.filter(x => x.isPublic === false)
const publicRoutes = RoutesConfigFlat.filter(x => x.isPublic || x.isPublic === undefined)


const AppRoutes = () => {

    customLogger("Renderizando routes")

    const { session, logout } = useSession()

    const renderRoutes = (routes) => {
        return routes.map((routeItem, index) => {
            return (
                <Route
                    key={index}
                    path={routeItem.path}
                    element={routeItem.element}
                />
            )
        }

        )
    }

    return (
        <Router>
            <Routes>
                {/* rutas privadas */}
                {session && (
                    <>
                        <Route
                            element={
                                <PrivateRoute session={session} element={<LayoutPage />} />
                            }
                        >
                            {renderRoutes(privateRoutes)}
                        </Route>
                    </>
                )}

                {/* rutas publicas */}
                <Route element={<LayoutPage />}>
                    {renderRoutes(publicRoutes)}
                </Route>
            </Routes>
        </Router>
    )
}
export default AppRoutes


const PrivateRoute = ({ element, session }) => {

    const navigate = useNavigate();
    useEffect(() => {
        if (!session) {
            navigate("/")
        }
    }, [session])

    return (<>
        {session ? (element) : (<Navigate to={"/"} replace />)}
    </>)
}


