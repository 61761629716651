import { Card, Col, Container, Row } from 'react-bootstrap'
import Products from '../../components/Products/Products'
import SidebarFilters from '../../components/Products/SidebarFilters'
import { debugLog } from '../../utils/utils'
import { MODULES } from '../../utils/constants'

const ProductsPage = () => {

    debugLog(MODULES .LAYOUT_PAGE, "Renderizando ProductsPage")
    return (
        <Container fluid={true} >

            <Row>
                <Col xs={12} md={3}>
                    
                    <SidebarFilters />
                </Col>
                <Col xs={12} md={9}>
                    <Products />
                </Col>
            </Row>

        </Container>

    )
}
export default ProductsPage