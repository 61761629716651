import React from 'react'
import { Card, Container } from 'react-bootstrap'
import ProductsList from '../../components/Admin/Products/ProductsList'



const AdminProductsPage = () => {

    return (
        <>
            <Container >
                <Card>
                    <Card.Header as={"h2"} className='text-center'>
                        Gestión de Productos de tiendas
                    </Card.Header>
                    <Card.Body>
                        <ProductsList />
                    </Card.Body>
                </Card>
            </Container>
        </>
    )
}
export default AdminProductsPage