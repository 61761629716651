import { useState, useEffect } from "react";
import { Card, Col, Container, Row, Table } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import ItemView from "../../components/Product/ItemView";


const ProductPage = () => {

    return (

        <Container >
            <ItemView />
        </Container >

    )
}
export default ProductPage