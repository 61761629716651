import React from 'react'
import useItemFavorite from "../../hooks/useItemFavorite"
import { faHeart as faHeartSolid } from "@fortawesome/free-solid-svg-icons";
import { faHeart as faHeartRegular } from "@fortawesome/free-regular-svg-icons";
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const FavoriteButtonIcon = ({ itemId }) => {

    const { isBookmarked, toggleItemFavorite } = useItemFavorite()

    const itemIdString=itemId.toString()
    return (
        <>
            {isBookmarked(itemIdString) ? (
                <Button
                    title='Quitar de favoritos'
                    variant="docpup-icon"
                    type="button"
                    onClick={() => toggleItemFavorite(itemIdString)}
                >
                    <FontAwesomeIcon icon={faHeartSolid} />
                </Button>

            ) : (
                <Button
                    title='Añadir a favoritos'
                    variant="docpup-icon"
                    type="button"
                    onClick={() => toggleItemFavorite(itemIdString)}
                >
                    <FontAwesomeIcon icon={faHeartRegular} />
                </Button>

            )}
        </>
    )
}
export default FavoriteButtonIcon