import React from 'react';
import { Col, Row, Tab, Tabs } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import 'react-toastify/dist/ReactToastify.css';
import TabEditItem from './TabEditItem';
import TabProducts from './TabProducts';
import { useEffect } from 'react';
import { useState } from 'react';
import { getItemById } from "../../../services/Items.service";
import { setIsLoading } from "../../../store/features/util/util.slice"
import { useDispatch } from 'react-redux';


const ModalEditItem = ({ show, hideModal, updateParent, currentItem, brands, weightUnits, species, agesRanges }) => {

    const dispatch=useDispatch()

    const [currentItemData, setCurrentItemData] = useState(null)

    useEffect(() => {
        setCurrentItemData(null)
        if (currentItem && currentItem.itemId > 0) {
            const fetchItem = async () => {
                dispatch(setIsLoading(true))
                try {
                    const itemData = await getItemById(currentItem.itemId)
                    setCurrentItemData(itemData)
                } catch (error) {
                    hideModal()

                } finally {
                    dispatch(setIsLoading(false))
                }
            }
            fetchItem()
        }

    }, [currentItem])

    return (
        <>

            <Modal show={show} onHide={() => hideModal()} size="xl">
                <Modal.Header closeButton>
                    <Row>
                        <Col xs={12}><Modal.Title>Edición de Item</Modal.Title></Col>
                        <Col xs={12}>Item: {currentItem?.name}</Col>
                        <Col xs={12}>Marca: {currentItem?.brandName}</Col>
                    </Row>
                </Modal.Header>
                <Modal.Body>
                    {currentItemData && currentItemData.itemId ? (
                        <Tabs defaultActiveKey="item">
                            <Tab title="Item" eventKey='item' >
                                <TabEditItem
                                    currentItem={currentItemData}
                                    hideModal={() => hideModal()}
                                    updateParent={() => updateParent()}
                                    brands={brands}
                                    weightUnits={weightUnits}
                                    species={species}
                                    agesRanges={agesRanges}
                                />
                            </Tab>
                            <Tab title="Productos" eventKey='productos'>
                                <TabProducts currentItem={currentItem} updateParent={() => updateParent()} />
                            </Tab>
                        </Tabs>
                    ) : (<>
                        <p>Cargando ...</p>
                    </>)}



                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => hideModal()}>
                        Cerrar
                    </Button>

                </Modal.Footer>
            </Modal>
        </>
    );
}
export default ModalEditItem;