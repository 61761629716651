// Session
export const SESSION_KEY = "SESSION_KEY"

// Favorites items Key
export const ITEMS_FAVORITES_KEY = "ITEMS_FAVORITES_KEY"

//Modules names
export const MODULES = {
    // PAGES
    HEADER_PAGE: "HEADER_PAGE",
    LAYOUT_PAGE: "LAYOUT_PAGE",
    PRODUCTS_PAGE: "PRODUCTS_PAGE",
    FAVOURITES_PAGE:"FAVOURITES_PAGE",
    // Admin Pages
    SHOPS_PAGE: "SHOPS_PAGE",
    // HOOKS
    USE_SESSION_HOOK: "USE_SESSION_HOOK",

    //ITEM FAVORITE
    USE_ITEM_FAVORITE: "USE_ITEM_FAVORITE",

    //Route config
    ROUTE_CONFIG: "ROUTE_CONFIG",



}


