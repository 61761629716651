import { Outlet } from 'react-router-dom'
import HeaderPage from './Header.page';
import Loader from '../../components/Shared/Loader';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { fetchMasterDataAsync, setIsLoading } from '../../store/features/util/util.slice';
import { useSession } from '../../hooks/useSession';
import { MODULES } from '../../utils/constants';
import { debugLog } from '../../utils/utils';


const LayoutPage = () => {

    debugLog(MODULES.LAYOUT_PAGE, "Renderizando LayoutPage")

    const dispatch = useDispatch()
    const { session, logout } = useSession()

    debugLog(MODULES.LAYOUT_PAGE, "Session en LayoutPage", session)


    const isLoading = useSelector(state => state.util.isLoading)


    useEffect(() => {
        debugLog(MODULES.LAYOUT_PAGE, "useEffect - Getting master data")
        const fetchData = async () => {
            debugLog(MODULES.LAYOUT_PAGE, "useEffect - fetching master data")
            dispatch(setIsLoading(true))
            await dispatch(fetchMasterDataAsync())
            dispatch(setIsLoading(false))
            debugLog(MODULES.LAYOUT_PAGE, "useEffect - end")
        }
        fetchData()
    }, [])

    return (
        <>
            {isLoading && (<Loader />)}
            <HeaderPage session={session} logout={logout} />
            <main className='mt-4'>
                <Outlet/>
            </main>
        </>
    )
}
export default LayoutPage

