import React, { useEffect, useState } from 'react';
import { Card, Col, Form, Row, Image } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import Button from 'react-bootstrap/Button';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import ModalAssignItem from './ModalAssignItem';
import { GetProducts, GetProductsBrands } from '../../../services/Products.service';
import { setIsLoading } from '../../../store/features/util/util.slice';
import ModalDeleteProduct from './ModalDeleteProduct';

const ProductsList = () => {

    const dispatch = useDispatch()

    const { getValues, setValue, handleSubmit, formState: { errors }, reset, control } = useForm(
        {
            defaultValues: {
                isForDog: true,
                isForCat: true,
                hasItemAssigned: null,
                shopId: "",
                brandName: "",
                tittle: ""
            }
        }
    );

    const [tableFilters, setTableFilters] = useState(null)

    const [products, setProducts] = useState([])
    const [selectedProduct, setSelectedProduct] = useState(null)
    const [productToDelete, setProductToDelete] = useState(null)



    const [productsBrandsList, setProductsBrandsList] = useState([])

    const [totalSize, setTotalSize] = useState(0)
    const [page, setPage] = useState(1)
    const [sizePerPage, setSizePerPage] = useState(10)

    const shopList = useSelector(state => state.util.shops)



    const fetchMasterData = async () => {
        dispatch(setIsLoading(true))
        try {
            setProductsBrandsList(await GetProductsBrands())
        } catch (error) {
            console.log("Ocurrio un error inesperado", error)
            toast.error("Ocurrio un error inesperado")
        }
        finally {
            dispatch(setIsLoading(false))
        }
    }

    useEffect(() => {
        fetchMasterData().then(() =>
            fetchData(page, sizePerPage)
        )
    }, [])

    const fetchData = async (page, sizePerPage, tableFilters) => {
        try {
            dispatch(setIsLoading(true))

            const filters = getValues()

            for (const key in filters) {
                if (Object.hasOwnProperty.call(filters, key)) {

                    if (key === "shopId" && (filters[key] === "0" || filters[key] === "")) {
                        filters[key] = null
                    }

                    if (key === "brandName" && (filters[key] === "0" || filters[key] === "")) {
                        filters[key] = null
                    }
                }
            }

            const response = await GetProducts({
                page: page, per_page: sizePerPage
                , ...filters
                , ...tableFilters
            })
            setProducts(response.data);
            setTotalSize(response.total)
            setPage(page)
            setSizePerPage(sizePerPage)

        } catch (error) {
            console.log("Ocurrio un error al consultar productos", error)
            toast.error("Ocurrio un error al consultar productos")

        } finally {
            dispatch(setIsLoading(false))
        }
    }

    const onSubmit = (data) => {

        fetchData(page, sizePerPage, tableFilters)
    }



    const accionesColumnFormater = (cell, row, rowIndex, formatExtraData) => {
        if (row) {
            return (
                <Row>
                    <Col xs={12}>
                        <Button onClick={() => setSelectedProduct(row)} >
                            {row.itemId ? 'Modificar item' : 'Asignar item'}
                        </Button>
                    </Col>
                    <Col xs={12}>
                        <Button variant='danger' onClick={() => setProductToDelete(row)} >
                            Eliminar
                        </Button>
                    </Col>
                    <Col xs={12}>
                        <a href={row.url} rel="noreferrer" target="_blank">Visitar Página</a>
                    </Col>
                </Row>
            )
        }
    }

    const especieProductoFormater = (cell, row, rowIndex, formatExtraData) => {
        if (row) {
            return (
                <>
                    {row.isForDog ? "Perros" : ""}
                    {row.isForCat ? "Gatos" : ""}
                </>
            )
        }
    }

    const tieneItemAsociadoFormater = (cell, row, rowIndex, formatExtraData) => {
        if (row) {
            return row.itemId ? "Si" : "No"
        }
    }

    const urlProductImageFormatter = (cell, row, rowIndex, formatExtraData) => {
        if (row && row.urlImage) {
            return (
                <>
                    <Image
                        src={row.urlImage}
                        // className="img-item"
                        alt="Imagen no disponible"
                        fluid
                        thumbnail
                        width={"50%"}
                    />
                </>)
        }
    }

    const columns = [
        {
            dataField: 'urlImage',
            text: 'Imagen',
            formatter: urlProductImageFormatter
        },
        {
            dataField: 'tittle',
            text: 'Nombre',
            // filter: textFilter({ placeholder: "Buscar por nombre", })
        }
        , {
            dataField: 'shop.shopName',
            text: 'Tienda',
            // filter: textFilter({ placeholder: "Buscar por tienda", })
        }
        , {
            dataField: 'brand',
            text: 'Marca',
            // filter: textFilter({ placeholder: "Buscar por marca", })
        }
        , {
            dataField: 'productId',
            text: 'Especie',
            formatter: especieProductoFormater
            // filter: textFilter({ placeholder: "Buscar por marca", })
        }
        , {
            dataField: 'itemId',
            text: 'Tiene Item',
            formatter: tieneItemAsociadoFormater
            // filter: textFilter({ placeholder: "Buscar por marca", })
        }
        , {
            dataField: "none",
            text: 'Acciones',
            formatter: accionesColumnFormater
        }];


    const options = {
        custom: false,
        page: page,
        sizePerPage: sizePerPage,
        totalSize: totalSize,
        paginationSize: 4,
        pageStartIndex: 1,
        firstPageText: 'Primera',
        prePageText: 'Anterior',
        nextPageText: 'Siguiente',
        lastPageText: 'Última',
        nextPageTitle: 'First page',
        prePageTitle: 'Pre page',
        firstPageTitle: 'Next page',
        lastPageTitle: 'Last page',
        showTotal: true,
        // alwaysShowAllBtns: true, // Always show next and previous button
        // withFirstAndLast: false, // Hide the going to First and Last page button
        // hideSizePerPage: true, // Hide the sizePerPage dropdown always
        // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
        // paginationTotalRenderer: customTotal,
        // disablePageTitle: true,
        sizePerPageList: [{
            text: '5', value: 5
        }, {
            text: '10', value: 10
        }] // A numeric array is also available. the purpose of above example is custom the text
    };

    const parseTableFilters = (tableFilters) => {
        let parsedFilters = {};
        const keysArray = Object.keys(tableFilters);
        keysArray.forEach(tableFilterProp => {
            switch (tableFilterProp) {
                case "tittle":
                    Object.assign(parsedFilters, { tittle: tableFilters[tableFilterProp].filterVal })
                    break;
                case "shop.shopName":
                    Object.assign(parsedFilters, { shopName: tableFilters[tableFilterProp].filterVal })
                    break;
                case "brand":
                    Object.assign(parsedFilters, { brand: tableFilters[tableFilterProp].filterVal })
                    break;
                default:
                    break;
            }
        });
        return parsedFilters;
    }

    const onTableChange = (type, { page, sizePerPage, filters }) => {
        // console.log(`Se invoca table change pagina: ${page} tamaño: ${sizePerPage} Filtros:`, filters)
        let parsedTableFilters = parseTableFilters(filters)
        setTableFilters(parsedTableFilters)
        fetchData(page, sizePerPage, parsedTableFilters)
    }

    const onChangeHasItemAssigned = (event, formOnChange) => {
        if (event.target.value && event.target.value !== '') {
            setValue("hasItemAssigned", event.target.value === "1")
        } else {
            setValue("hasItemAssigned", null)
        }
    }

    return (<>
        <ModalDeleteProduct
            setShow={() => setProductToDelete(null)}
            show={productToDelete}
            updateParent={() => fetchData(page, sizePerPage)}
            currentProduct={productToDelete}
        />
        <ModalAssignItem
            setShow={() => setSelectedProduct(null)}
            show={selectedProduct}
            updateParent={() => fetchData(page, sizePerPage)}
            currentProduct={selectedProduct}
        />
        <Row>
            <Col>
                <Card>
                    <Card.Body>
                        <Card.Title>
                            Filtros
                        </Card.Title>
                        <br></br>
                        <Form onSubmit={handleSubmit(onsubmit)}>

                            <Row>
                                <Col xs={12} md={3}>
                                    <Form.Group>

                                        <Form.Label>Nombre</Form.Label>
                                        <Controller
                                            control={control}
                                            name="tittle"
                                            render={({ field: { onChange, onBlur, value, ref } }) => (
                                                <Form.Control
                                                    type='text'
                                                    onChange={onChange}
                                                    onBlur={onBlur}
                                                    value={value}
                                                />
                                            )}
                                        >
                                        </Controller>
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={3}>
                                    <Form.Group>

                                        <Form.Label>Tiene item asignado</Form.Label>
                                        <Controller
                                            control={control}
                                            name="hasItemAssigned"
                                            render={({ field: { onChange, value, ref } }) => (
                                                <Form.Select
                                                    onChange={(e) => onChangeHasItemAssigned(e)}
                                                >
                                                    <option value="">Seleccione </option>
                                                    <option value="0">No </option>
                                                    <option value="1">Si </option>


                                                </Form.Select>
                                            )}
                                        >
                                        </Controller>
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={3}>
                                    <Form.Group>
                                        <Form.Label>Tienda</Form.Label>
                                        <Controller
                                            control={control}
                                            name="shopId"
                                            render={({ field: { onChange, onBlur, value, ref } }) => (
                                                <Form.Select
                                                    onChange={onChange}
                                                    onBlur={onBlur}
                                                    value={value}
                                                >
                                                    <option value={0}>Seleccione tienda</option>
                                                    {shopList && shopList.map(shop => {
                                                        return (
                                                            <option key={shop.shopId} value={shop.shopId}>{shop.name}</option>
                                                        )
                                                    })}

                                                </Form.Select>
                                            )}
                                        >
                                        </Controller>
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={3}>
                                    <Form.Group>
                                        <Form.Label>Marcas</Form.Label>
                                        <Controller
                                            control={control}
                                            name="brandName"
                                            render={({ field: { onChange, onBlur, value, ref } }) => (
                                                <Form.Select
                                                    onChange={onChange}
                                                    onBlur={onBlur}
                                                    value={value}
                                                >
                                                    <option value="">Seleccione Marca</option>
                                                    {productsBrandsList && productsBrandsList.map(brand => {
                                                        return (
                                                            <option key={brand.brand} value={brand.brand}>{brand.brand}</option>
                                                        )
                                                    })}
                                                </Form.Select>
                                            )}
                                        >
                                        </Controller>
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={3}>
                                    <Form.Group>
                                        <Form.Label>Especie</Form.Label>
                                        <Controller
                                            control={control}
                                            name="isForDog"
                                            render={({ field: { onChange, onBlur, value, ref } }) => (
                                                <Form.Check
                                                    type="switch"
                                                    id="isForDog"
                                                    label="Perros"
                                                    onChange={onChange}
                                                    onBlur={onBlur}
                                                    value={value}
                                                    defaultChecked={value}
                                                />
                                            )}
                                        ></Controller>
                                        <Controller
                                            control={control}
                                            name="isForCat"
                                            render={({ field: { onChange, onBlur, value, ref } }) => (
                                                <Form.Check
                                                    type="switch"
                                                    id="isForCat"
                                                    label="Gatos"
                                                    onChange={onChange}
                                                    onBlur={onBlur}
                                                    value={value}
                                                    defaultChecked={value}
                                                />
                                            )}
                                        ></Controller>
                                    </Form.Group>

                                </Col>
                                <Col xs={12} className='my-3'>
                                    <Button onClick={handleSubmit(onSubmit)}>Aplicar Filtros</Button>
                                </Col>
                            </Row>
                        </Form>
                    </Card.Body>

                </Card>


            </Col>
        </Row>
        <Row className='my-3'>
            <Col>
                <Card>
                    <Card.Body>
                        <Card.Title>
                            Registros
                        </Card.Title>
                        <br></br>
                        <BootstrapTable
                            bootstrap4
                            remote
                            keyField='productId'
                            data={products}
                            columns={columns}
                            pagination={paginationFactory(options)}
                            onTableChange={onTableChange}
                            filter={filterFactory()}
                            hover />
                    </Card.Body>
                </Card>
            </Col>

        </Row>
        <Row>
            <Col>
                {/* <Button onClick={() => onConfirmChanges()}>Confirmar cambios</Button> */}
            </Col>
        </Row>
    </>

    );
}
export default ProductsList;