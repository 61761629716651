import { useEffect, useState } from 'react';
import { Card, Col, Form, Image, Row } from 'react-bootstrap';
import { toast } from "react-toastify";
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart as faHeartRegular } from "@fortawesome/free-regular-svg-icons";
import { useMediaQuery } from '@uidotdev/usehooks';
import useItemFavorite from '../../hooks/useItemFavorite';
import { formatNumber, GetLogger } from '../../utils/utils';
import FavoriteButtonIcon from "../Shared/FavoriteButtonIcon"
import { getItems } from '../../services/Items.service';
import { setIsLoading } from '../../store/features/util/util.slice';
import { MODULES } from '../../utils/constants';
import './MyFavourites.scss';

const customLogger = GetLogger(MODULES.FAVOURITES_PAGE)

const MyFavourites = () => {

    
    const [items, setItems] = useState([])
    const [orderBy, setOrderBy] = useState("asc")

    const { favorites } = useItemFavorite()
    const dispatch = useDispatch()

    useEffect(() => {
        const fetchData = async () => {
            dispatch(setIsLoading(true))
            try {
                customLogger("Listado favoritos", favorites)
                let payload = new URLSearchParams()
                for (let index = 0; index < favorites.length; index++) {
                    payload.append("itemsIds[]", favorites[index])
                }
                customLogger("Payload", payload)

                const items = await getItems(payload)
                // item.shops?.sort(orderByPrice)
                setItems(items)

            } catch (error) {
                console.log(error)
                toast.error("Error al obtener favoritos")
            } finally {
                dispatch(setIsLoading(false))
            }

        }
        if (favorites && favorites.length > 0) {
            fetchData()
        }

    }, [])

    const isSmallDevice = useMediaQuery("only screen and (max-width: 576px)");

    const history = useNavigate()

    const onChangeOrderBy = (valor) => {

        let orderByFieldType = "asc"

        switch (valor) {
            case "1":
                orderByFieldType = "asc"
                break;
            case "2":
                orderByFieldType = "desc"
                break;
            default:
                break;
        }
        setOrderBy(orderByFieldType)

    }
    const sortItems = (a, b) => {
        if (orderBy === "asc") {
            return a.fromPrice - b.fromPrice
        } else {
            return b.fromPrice - a.fromPrice
        }
    }

    return (
        <>
            {!favorites || favorites.length == 0 ?
                (<Card className="claymorphism-item item-view my-3 text-center ">
                    <Card.Body >
                        <Card.Title className="my-3">
                            {" "}
                            No se han seleccionado favoritos
                        </Card.Title>
                        <p>
                            Puedes utilizar el icono <FontAwesomeIcon icon={faHeartRegular} /> para agregar productos a tu listado de favoritos
                        </p>
                    </Card.Body>

                </Card>

                )
                :
                (
                    <Card className='products-section'>
                        <Card.Body>

                            <Row>
                                <Col xs={12} md={{ offset: 8, span: 4 }}>
                                    <Form.Group className="mb-3" >
                                        <Form.Label className="font-weight-bold">Ordenar Por</Form.Label>
                                        <Form.Select
                                            onChange={(e) => onChangeOrderBy(e.target.value)}
                                        >
                                            <option value="1">Precio de menor a mayor</option>
                                            <option value="2">Precio de mayor a menor</option>
                                        </Form.Select>

                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row>
                                <div
                                    className={`d-flex flex-row flex-wrap ${isSmallDevice ? "justify-content-center" : "justify-content-between"} `}
                                >
                                    {items?.data?.sort(sortItems).map(item => {
                                        return (
                                            <div key={item.itemId} className='d-flex flex-column item-result '>
                                                <div>
                                                    <Link to={`/${item.itemId}`}>{item.name}</Link>
                                                    <Image
                                                        onClick={() => history(`/${item.itemId}`)}
                                                        src={item.imageUrl}
                                                        fluid={true}

                                                    />
                                                </div>


                                                <div> <strong>Marca: </strong>{item?.brandName}</div>
                                                <div className='item-price'>${item?.fromPrice ? formatNumber(item?.fromPrice) : ""}</div>
                                                <div className='mt-auto pt-2 pb-2 align-self-center'><FavoriteButtonIcon itemId={item.itemId} /></div>
                                            </div>
                                        )
                                    })}

                                </div>

                            </Row>
                        </Card.Body>
                    </Card >
                )
            }
        </>
    )
}
export default MyFavourites