
import { Card, Col, Form, Image, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'
import './Products.scss';
import CustomPagination from './CustomPagination';
import { formatNumber } from '../../utils/utils';
import { useMediaQuery } from '@uidotdev/usehooks';
import FavoriteButtonIcon from "../Shared/FavoriteButtonIcon"

const Products = () => {


    const items = useSelector(state => state.search.items)
    const itemsFilters = useSelector(state => state.search.itemsFilters)
    const isSmallDevice = useMediaQuery("only screen and (max-width: 576px)");

    const history = useNavigate()

    const handlePageChange = (pageNumber) => {
        const formData = { ...itemsFilters, page: pageNumber }
        history(`?${new URLSearchParams(formData)}`)

    }
    const onChangeOrderBy = (valor) => {

        let orderByField = "price"
        let orderByFieldType = "asc"

        switch (valor) {
            case "1":
                orderByFieldType = "asc"
                break;
            case "2":
                orderByFieldType = "desc"
                break;
            default:
                break;
        }
        const formData = { ...itemsFilters, orderBy: orderByField, orderByType: orderByFieldType }
        history(`?${new URLSearchParams(formData)}`)
    }

    return (
        <Card className='products-section'>
            <Card.Body>
                <Row>
                    <Col xs={12} md={{ offset: 8, span: 4 }}>
                        <Form.Group className="mb-3" >
                            <Form.Label className="font-weight-bold">Ordenar Por</Form.Label>
                            <Form.Select
                                onChange={(e) => onChangeOrderBy(e.target.value)}
                            >
                                <option value="1">Precio de menor a mayor</option>
                                <option value="2">Precio de mayor a menor</option>
                            </Form.Select>

                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <div
                        className={`d-flex flex-row flex-wrap ${isSmallDevice ? "justify-content-center" : "justify-content-between"} `}
                    >
                        {items?.data?.map(item => {
                            return (
                                <div key={item.itemId} className='d-flex flex-column item-result '>
                                    <div>
                                        <Link to={`/${item.itemId}`}>{item.name}</Link>
                                        <Image
                                            onClick={() => history(`/${item.itemId}`)}
                                            src={item.imageUrl}
                                            fluid={true}

                                        />
                                    </div>


                                    <div> <strong>Marca: </strong>{item?.brandName}</div>
                                    <div className='item-price'>${item?.fromPrice ? formatNumber(item?.fromPrice) : ""}</div>
                                    <div className='mt-auto pt-2 pb-2 align-self-center'><FavoriteButtonIcon itemId={item.itemId} /></div>
                                </div>
                            )
                        })}

                    </div>

                </Row>
                <Row className='my-2'>
                    <Col className='d-flex justify-content-center'>
                        <CustomPagination
                            currentPage={items?.current_page}
                            pageSize={items?.per_page}
                            totalCount={items?.total}
                            siblingCount={1}
                            onPageChange={(pageNumber) => handlePageChange(pageNumber)}
                        />
                    </Col>
                </Row>
            </Card.Body>
        </Card >


    )
}
export default Products