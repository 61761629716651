import { getUserToken } from "./User.service"

const URL_API = process.env.REACT_APP_URL_API


/**
 * @typedef {object} ShopAdminDto
 * @property {number} shopId
 * @property {string} name
 * @property {string} url
 * @property {boolean} active
 * @property {string} scrappingClass
 * 
 * 
 */


/**
 * 
 * @param {*} filters 
 * @returns {Promise<ShopAdminDto[]>}
 */
export const getShopsAdmin = async (filters) => {

    let url = `${URL_API}/admin/shops?`;
    if (filters)
        url += new URLSearchParams(filters)

    return await fetch(url, {
        method: "GET",
        mode: 'cors',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getUserToken()}`
        }

    })
        .then(response => {
            if (response.status >= 200 && response.status < 300) {
                return response.json()
            } else {
                throw Error(`${response.statusText} ${response.status}`)
            }
        })
        .then(data => {
            return data;

        })
        .catch(error => {
            console.log(error)
            throw error;
        })
}


export const getShopDetail = async (idShop) => {

    let url = `${URL_API}/shops/${idShop}/detail`;


    return await fetch(url, {
        method: "GET",
        mode: 'cors',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getUserToken()}`
        }

    })
        .then(response => {
            if (response.status >= 200 && response.status < 300) {
                return response.json()
            } else {
                throw Error(`${response.statusText} ${response.status}`)
            }
        })
        .then(data => {
            return data;

        })
        .catch(error => {
            console.log(error)
            throw error;
        })
}


export const UpdateShopAdmin = async (shopId, payload) => {
    return await fetch(`${URL_API}/admin/shops/${shopId}`, {
        method: "PUT",
        mode: 'cors',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getUserToken()}`
        },
        body: JSON.stringify(payload)
    })
        .then(response => {
            if (response.status >= 200 && response.status < 300) {
                return response.json()
            } else {
                throw Error(`${response.statusText} ${response.status}`)
            }
        })
        .then(data => {
            return data;

        })
        .catch(error => {
            console.log(error)
            throw error;
        })
}