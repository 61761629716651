import { getUserToken } from "./User.service"

const URL_API = process.env.REACT_APP_URL_API


export const UpdateProduct = async (productId, payload) => {

    return await fetch(`${URL_API}/products/${productId}`, {
        method: "PUT",
        mode: 'cors',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getUserToken()}`
        },
        body: JSON.stringify(payload)
    })
        .then(response => {
            if (response.status >= 200 && response.status < 300) {
                return response.json()
            } else {
                throw Error(`${response.statusText} ${response.status}`)
            }
        })
        .then(data => {
            return data;
        })
        .catch(error => {
            console.log(error)
            throw error;
        })
}
export const GetProducts = async (params) => {

    return await fetch(`${URL_API}/products?${new URLSearchParams(params)}`, {
        method: "GET",
        mode: 'cors',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },

    })
        .then(response => {
            if (response.status >= 200 && response.status < 300) {
                return response.json()
            } else {
                throw Error(`${response.statusText} ${response.status}`)
            }
        })
        .then(data => {
            return data;
        })
        .catch(error => {
            console.log(error)
            throw error;
        })
}

export const GetProductById = async (productId) => {

    return await fetch(`${URL_API}/products/${productId}`, {
        method: "GET",
        mode: 'cors',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },

    })
        .then(response => {
            if (response.status >= 200 && response.status < 300) {
                return response.json()
            } else {
                throw Error(`${response.statusText} ${response.status}`)
            }
        })
        .then(data => {
            return data;
        })
        .catch(error => {
            console.log(error)
            throw error;
        })
}

export const DeleteProductById = async (productId) => {

    return await fetch(`${URL_API}/products/${productId}`, {
        method: "DELETE",
        mode: 'cors',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getUserToken()}`
        },

    })
        .then(response => {
            if (response.status >= 200 && response.status < 300) {
                return response.json()
            } else {
                throw Error(`${response.statusText} ${response.status}`)
            }
        })
        .then(data => {
            return data;
        })
        .catch(error => {
            console.log(error)
            throw error;
        })
}

export const GetProductsBrands = async () => {
    return await fetch(`${URL_API}/products/brands`, {
        method: "GET",
        mode: 'cors',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getUserToken()}`
        }
    })
        .then(response => {
            if (response.status >= 200 && response.status < 300) {
                return response.json()
            } else {
                throw Error(`${response.statusText} ${response.status}`)
            }
        })
        .then(data => {
            return data;

        })
        .catch(error => {
            console.log(error)
            throw error;
        })
}