import React from 'react'
import { Card, Container } from 'react-bootstrap'
import ItemList from '../../components/Admin/Items/ItemList'



const ItemsPage = () => {

    return (
        <>
            <Container >
                <Card>
                    <Card.Header as={"h2"} className='text-center'>
                        Gestión de Items
                    </Card.Header>
                    <Card.Body>
                        <ItemList />
                    </Card.Body>
                </Card>

            </Container>
        </>
    )
}
export default ItemsPage