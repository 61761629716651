import React from 'react';
import { Form } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { SaveItem } from '../../../services/Items.service';
import { setIsLoading } from '../../../store/features/util/util.slice';

const ModalNewItem = ({ show, setShow, updateParent }) => {


    const brands = useSelector(state => state.util.brands)
    const species = useSelector(state => state.util.species)
    const agesRanges = useSelector(state => state.util.ageRanges)
    const weightUnits = useSelector(state => state.util.weightUnits)

    const dispatch = useDispatch()


    const handleClose = () => setShow(false);

    const { register, handleSubmit, formState: { errors }, reset, control } = useForm(
        {
            defaultValues: {
                itemName: "",
                urlItem: "",
                itemWeight: "",
                itemWeightUnitId: "",
                itemSpeciesId: "",
                itemAgeRangeId: "",
                itemBrandId: ""
            }
        }

    );


    const onSubmit = async (data) => {
        console.log(JSON.stringify(data))

        try {
            dispatch(setIsLoading(true))
            const response = await SaveItem(data);

            if (response.success) {
                toast.success(response.message)
                setShow(false)
                reset()
                updateParent()

            } else {
                toast.error(response.message + ": " + JSON.stringify(response.payload))
            }
        } catch (error) {
            toast.error("Ocurrio un error inesperado: " + error)
        } finally {
            dispatch(setIsLoading(false))
        }

    }


    return (
        <>

            <Modal show={show} onHide={handleClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Nuevo item</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Form.Group className="mb-3" >
                            <Form.Label>Nombre</Form.Label>
                            <Form.Control
                                type="text"
                                {
                                ...register("itemName",
                                    {
                                        required: true,
                                        maxLength: 100
                                    }
                                )}
                            />
                            {errors.itemName && errors.itemName.type === 'required' && (
                                <Form.Text className="text-danger">
                                    Este campo es requerido.
                                </Form.Text>
                            )}
                            {errors.itemName && errors.itemName.type === 'maxLength' && (
                                <Form.Text className="text-danger">
                                    Largo máximo 100 caracteres.
                                </Form.Text>
                            )}

                        </Form.Group>

                        <Form.Group className="mb-3" >
                            <Form.Label>Marca</Form.Label>
                            <Controller
                                control={control}
                                name="itemBrandId"
                                rules={{
                                    required: true
                                }}
                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <Form.Select
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        value={value}
                                    >
                                        <option>Seleccione marca</option>
                                        {brands && brands.map(brand => {
                                            return (
                                                <option key={brand.brandId} value={brand.brandId}>{brand.name}</option>
                                            )
                                        })}
                                    </Form.Select>
                                )}
                            >
                            </Controller>
                            {errors.itemBrand && errors.itemBrand.type === 'required' && (
                                <Form.Text className="text-danger">
                                    Este campo es requerido.
                                </Form.Text>
                            )}
                        </Form.Group>
                        <Form.Group className="mb-3" >
                            <Form.Label>Especie</Form.Label>
                            <Controller
                                control={control}
                                name="itemSpeciesId"
                                rules={{
                                    required: true
                                }}
                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <Form.Select
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        value={value}
                                    >
                                        <option>Seleccione</option>
                                        {species && species.map(specie => {
                                            return (
                                                <option value={specie.speciesId} key={specie.speciesId}>{specie.species}</option>
                                            )
                                        })}
                                    </Form.Select>
                                )}
                            >
                            </Controller>
                            {errors.itemBrand && errors.itemBrand.type === 'required' && (
                                <Form.Text className="text-danger">
                                    Este campo es requerido.
                                </Form.Text>
                            )}
                        </Form.Group>
                        <Form.Group className="mb-3" >
                            <Form.Label>Rango Etario</Form.Label>
                            <Controller
                                control={control}
                                name="itemAgeRangeId"
                                rules={{
                                    required: true
                                }}
                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <Form.Select
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        value={value}
                                    >
                                        <option>Seleccione</option>
                                        {agesRanges && agesRanges.map(ageRange => {
                                            return (
                                                <option value={ageRange.ageRangeId} key={ageRange.ageRangeId}>{ageRange.ageRange}</option>
                                            )
                                        })}
                                    </Form.Select>
                                )}
                            >
                            </Controller>
                            {errors.itemBrand && errors.itemBrand.type === 'required' && (
                                <Form.Text className="text-danger">
                                    Este campo es requerido.
                                </Form.Text>
                            )}
                        </Form.Group>
                        <Form.Group className="mb-3" >
                            <Form.Label>Url Imagen</Form.Label>
                            <Form.Control
                                type="text"
                                {...register("urlItem",
                                    {
                                        required: true,
                                        maxLength: 300
                                    }
                                )}
                            />
                            {errors.urlItem && errors.urlItem.type === 'required' && (
                                <Form.Text className="text-danger">
                                    Este campo es requerido.
                                </Form.Text>
                            )}
                            {errors.urlItem && errors.urlItem.type === 'maxLength' && (
                                <Form.Text className="text-danger">
                                    Largo máximo 300 caracteres.
                                </Form.Text>
                            )}
                        </Form.Group>

                        <Form.Group className="mb-3" >
                            <Form.Label>Masa</Form.Label>
                            <Form.Control
                                type="number"
                                {...register("itemWeight",
                                    { required: true }
                                )}
                            />
                            {errors.itemWeight && errors.itemWeight.type === 'required' && (
                                <Form.Text className="text-danger">
                                    Este campo es requerido.
                                </Form.Text>
                            )}
                        </Form.Group>
                        <Form.Group className="mb-3" >
                            <Form.Label>Unidad de masa</Form.Label>
                            <Controller
                                control={control}
                                name="itemWeightUnitId"
                                rules={{
                                    required: true,
                                }}

                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <Form.Select
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        value={value}
                                        required={true}

                                    >
                                        <option>Seleccione unidad de masa</option>
                                        {weightUnits && weightUnits.map(item => {
                                            return (
                                                <option key={item.weightUnitId} value={item.weightUnitId}>{item.name}</option>
                                            )
                                        })}
                                    </Form.Select>
                                )}
                            >
                            </Controller>
                            {errors.itemWeightUnit && errors.itemWeightUnit.type === 'required' && (
                                <Form.Text className="text-danger">
                                    Este campo es requerido.
                                </Form.Text>
                            )}

                        </Form.Group>


                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Cancelar
                    </Button>
                    <Button variant="primary" onClick={handleSubmit(onSubmit)}>
                        Guardar
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
export default ModalNewItem;