import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory from 'react-bootstrap-table2-filter';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch } from 'react-redux';
import { setIsLoading } from '../../../store/features/util/util.slice';
import { useForm } from 'react-hook-form';
import { UpdateShopAdmin, getShopsAdmin } from '../../../services/Shop.service';
import { MODULES } from '../../../utils/constants';
import { debugLog } from '../../../utils/utils';
import { toast } from 'react-toastify';
// import ModalLogsDetail from './ModalLogsDetail';

const ShopsList = () => {

    const dispatch = useDispatch()

    const [shopSelected, setShopSelected] = useState(null)

    const [shopsList, setShopsList] = useState([])

    useEffect(() => {
        fetchData(1, 10)
    }, [])

    const fetchData = async (filters = []) => {
        debugLog(MODULES.SHOPS_PAGE, "fetchData")

        dispatch(setIsLoading(true))
        try {

            const response = await getShopsAdmin({
                ...filters
            })
            setShopsList(response);

        } catch (error) {
            console.log("Ocurrio un error al obtener registros", error)
            setShopsList([])
        } finally {
            dispatch(setIsLoading(false))
        }

    }
    const handleOnActiveClick = async (shopId, newActiveState) => {
        dispatch(setIsLoading(true))
        try {
            debugLog(MODULES.SHOPS_PAGE, `handleOnActiveClick. Id: ${shopId}`)

            const updateResult = await UpdateShopAdmin(shopId, { active: newActiveState })

            if (updateResult.success) {
                fetchData()
                toast.success(updateResult.message)

            } else {
                toast.error(updateResult.message + ": " + JSON.stringify(updateResult.payload))
            }
        } catch (error) {
            console.log(error)
            toast.error("Ocurrio un error inesperado, intente nuevamente")

        } finally {
            dispatch(setIsLoading(false))
        }


    }
    const columns = [
        {
            dataField: 'shopId',
            text: 'ID',
        },
        {
            dataField: 'name',
            text: 'name',
        },
        {
            dataField: 'active',
            text: 'Estado',
            formatter: (cell, row, rowIndex, formatExtraData) => {
                if (row) {
                    return (cell ? "Activo" : "Inactivo")
                }
            }
        },
        {
            dataField: 'url',
            text: 'Url',
        },
        {
            dataField: 'scrappingClass',
            text: 'Clase',
        },
        {
            dataField: 'shopId',
            text: 'Acciones',
            formatter: (cell, row, rowIndex, formatExtraData) => {
                if (row) {
                    return (
                        <Form>
                            <Form.Check
                                type="switch"
                                id="switchActivate"
                                label={row.active ? "Deshabilitar" : "Habilitar"}
                                checked={row.active ? 1 : 0}
                                // onClick={handleOnActiveClick}
                                onChange={() => handleOnActiveClick(row.shopId, !row.active)}
                            >
                            </Form.Check>
                        </Form>

                    )
                }
            }

            // formatter: (cell, row, rowIndex, formatExtraData) => {
            //     if (row) {
            //         return (
            //             <Button
            //                 size='sm'
            //                 onClick={() => setShopSelected(row.shopId)}
            //             >
            //                 Ver detalle
            //             </Button>
            //         )
            //     }
            // }
        }
    ];




    const options = {
        // page: paginationData.Page,
        // sizePerPage: paginationData.SizePerPage,
        totalSize: shopsList?.length,
        paginationSize: 4,
        pageStartIndex: 1,
        firstPageText: 'Primera',
        prePageText: 'Anterior',
        nextPageText: 'Siguiente',
        lastPageText: 'Última',
        nextPageTitle: 'First page',
        prePageTitle: 'Pre page',
        firstPageTitle: 'Next page',
        lastPageTitle: 'Last page',
        showTotal: false,
        disablePageTitle: true,
        sizePerPageList: [{
            text: '5', value: 5
        }, {
            text: '10', value: 10
        }]
    };

    const onSubmit = (data) => {
        let formData = {};

        if (data.processUUID)
            Object.assign(formData, { processUUID: data.processUUID })

        fetchData(formData)
    }


    return (
        <>
            {/* <ModalLogsDetail
                show={webScrappingLogSelected ? true : false}
                hideModal={() => setWebScrappingLogSelected(null)}
                webScrappingLogId={webScrappingLogSelected}
            /> */}
            {/* <Row>
                <Col>
                    <Card>
                        <Card.Body>
                            <Card.Title>Filtros</Card.Title>
                            <Form onSubmit={handleSubmit(onSubmit)}>
                                <Row>
                                    <Col xs={12} md={3}>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>UUID Proceso</Form.Label>
                                            <Form.Control
                                                type="text"
                                                {
                                                ...register("processUUID",
                                                    {
                                                        maxLength: 40
                                                    }
                                                )}
                                            />
                                            {errors.processUUID && errors.processUUID.type === 'maxLength' && (
                                                <Form.Text className="text-danger">
                                                    Largo máximo 100 caracteres.
                                                </Form.Text>
                                            )}

                                        </Form.Group>
                                    </Col>

                                </Row>
                            </Form>
                            <Row>
                                <Col>
                                    <button
                                        className='btn btn-docpup'
                                        onClick={handleSubmit(onSubmit)}>Filtrar
                                    </button>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>

                </Col>
            </Row> */}

            <Row className='my-3'>
                <Col>
                    <Card>
                        <Card.Body>
                            <Card.Title>
                                Registros
                            </Card.Title>
                            <br></br>
                            <Row className='my-3'>
                                <Col xs={12}>

                                </Col>
                                <Col>
                                    <BootstrapTable
                                        bootstrap4
                                        remote
                                        keyField='shopId'
                                        data={shopsList}
                                        columns={columns}
                                        pagination={paginationFactory(options)}
                                        filter={filterFactory()}
                                        hover />
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    );
}
export default ShopsList;