import AdminItemsPage from '../pages/Admin/AdminItems.page'
import AdminProductsPage from '../pages/Admin/AdminProducts.page'
import ProductsPage from '../pages/Public/Products.page'
import ProductPage from '../pages/Public/Product.page'
import LoginPage from '../pages/Login.page'
import WebScrappingLogsPage from '../pages/Admin/WebScrappingLogs.page';
import ShopsPage from '../pages/Admin/Shops.page';
import MyFavouritesPage from '../pages/Public/MyFavourites.page'

/**
 * @typedef {Object} RouteConfigDto
 * @property 
 * @property {string|undefined} label Etiqueta a mostrar cuando se despliegue como opción de menú
 * @property {string} path Ruta de la opción
 * @property {React.ReactElement} element Componente de react a renderizar
 * @property {boolean} isPublic Indica si es una ruta publica o privada
 * @property {boolean} [showInMenu] Indica si se debe mostrar como opción de menú
 * @property {boolean} exact Indica el tipo de coincidencia con react-router-dom
 */


/**
 * Configuracion de las rutas en formato de objeto.
 */
export const RoutesConfig = {
    AdminItems: {
        label: "Admin items",
        path: '/admin/items',
        element: <AdminItemsPage />,
        isPublic: false,
        showInMenu: true,
        exact: true
    },
    AdminProducts:
    {
        label: "Admin productos",
        path: '/admin/products',
        element: < AdminProductsPage />,
        isPublic: false,
        showInMenu: true,
        exact: true
    },
    AdminShops:
    {
        label: "Tiendas",
        path: '/admin/shops',
        element: < ShopsPage />,
        isPublic: false,
        showInMenu: true,
        exact: true
    },
    AdminLogs:
    {
        label: "Logs",
        path: '/admin/logs',
        element: < WebScrappingLogsPage />,
        isPublic: false,
        showInMenu: true,
        exact: true
    },

    // rutas publicas
    Login:
    {
        path: '/login',
        element: (< LoginPage />),
        isPublic: true,
        exact: true
    },
    Products:
    {
        label: "Inicio",
        path: '/',
        element: (<ProductsPage />),
        isPublic: true,
        exact: true
    },
    ProductById:
    {
        path: '/:productId',
        element: <ProductPage />,
        isPublic: true,
        exact: true
    },
    MyFavourites:
    {

        label: "Mis favoritos",
        path: '/my-favourites',
        element: (<MyFavouritesPage />),
        isPublic: true,
        showInMenu: true,
        exact: true
    },

}

/**
 * Listado de rutas en formato de arreglo 
 * @type {Array<RouteConfigDto>}
 */
export const RoutesConfigFlat = Object.keys(RoutesConfig).map((key) => RoutesConfig[key])

/**
 * Listado de rutas publicas en formato de arreglo 
 * @type {Array<RouteConfigDto>}
 */
export const PublicRoutesConfigFlat = RoutesConfigFlat.filter(x => x.isPublic || x.isPublic === undefined)

/**
 * Listado de rutas privadas en formato de arreglo 
 * @type {Array<RouteConfigDto>}
 */
export const PrivateRoutesConfigFlat = RoutesConfigFlat.filter(x => !x.isPublic)