import React, { useEffect, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter, Comparator } from 'react-bootstrap-table2-filter';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useDispatch } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import { setIsLoading } from '../../../store/features/util/util.slice';
import { getWebScrappingLogsDetail } from '../../../services/WebScrappingLogs.service';
import { Col, Row, Card } from 'react-bootstrap';
import dayjs from 'dayjs';

const ModalLogsDetail = ({ show, hideModal, webScrappingLogId }) => {


    const dispatch = useDispatch()

    const [webScrappingLogDetails, setWebScrappingLogDetails] = useState([])

    useEffect(() => {
        if (webScrappingLogId) {
            fetchData()
        }

    }, [webScrappingLogId])

    const fetchData = async () => {

        dispatch(setIsLoading(true))
        try {

            const response = await getWebScrappingLogsDetail(webScrappingLogId)
            setWebScrappingLogDetails(response);

        } catch (error) {
            console.log("Ocurrio un error al obtener registros", error)
        } finally {
            dispatch(setIsLoading(false))
        }

    }

    const shopNameTextFilter = textFilter({
        placeholder: 'Filtrar por tienda',  // custom the input placeholder
        caseSensitive: false, // default is false, and true will only work when comparator is LIKE
        comparator: Comparator.EQ, // default is Comparator.LIKE
        // className: 'my-custom-text-filter', // custom classname on input
        // defaultValue: 'test', // default filtering value
        // style: { ... }, // your custom styles on input
        // delay: 1000, // how long will trigger filtering after user typing, default is 500 ms
        // getFilter: (f) => { ... }, // accept callback function and you can call it for filter programmtically
        id: 'idShopNameTextFilter', // assign a unique value for htmlFor attribute, it's useful when you have same dataField across multiple table in one page
    });

    const logMessageTextFilter = textFilter({
        placeholder: 'Filtrar por mensaje',  // custom the input placeholder
        caseSensitive: false, // default is false, and true will only work when comparator is LIKE
        comparator: Comparator.LIKE,
        id: 'idLogMessageTextFilter', // assign a unique value for htmlFor attribute, it's useful when you have same dataField across multiple table in one page
        // className: 'my-custom-text-filter', // custom classname on input
        // defaultValue: 'test', // default filtering value
        // style: { ... }, // your custom styles on input
        // delay: 1000, // how long will trigger filtering after user typing, default is 500 ms
        // getFilter: (f) => { ... }, // accept callback function and you can call it for filter programmtically

    });

    const columns = [
        {
            dataField: 'webScrappingShopLogId',
            text: 'Shop Log Id',
            sort: true,
        },
        {
            dataField: 'shop.name',
            text: 'Tienda',
            sort: true,
            filter: shopNameTextFilter
        },
        {
            dataField: 'message',
            text: 'Mensaje',
            sort: true,
            filter: logMessageTextFilter
        },
        {
            dataField: 'datetime',
            text: 'Fecha',
            sort: true,
            formatter: (cell, row, rowIndex, formatExtraData) => {
                if (row) {
                    return dayjs(row.datetime).format("DD-MM-YYYY HH:mm:ss")
                }
            }
        },
        // {
        //     dataField: 'datetime',
        //     text: 'Hora',
        //     sort: true,
        //     formatter: (cell, row, rowIndex, formatExtraData) => {
        //         if (row) {
        //             return dayjs(row.datetime).format("HH:mm:ss")
        //         }
        //     }
        // },
    ];

    const options = {
        // page: paginationData.Page,
        // sizePerPage: paginationData.SizePerPage,
        // totalSize: paginationData.TotalSize,
        paginationSize: 4,
        pageStartIndex: 1,
        firstPageText: 'Primera',
        prePageText: 'Anterior',
        nextPageText: 'Siguiente',
        lastPageText: 'Última',
        nextPageTitle: 'First page',
        prePageTitle: 'Pre page',
        firstPageTitle: 'Next page',
        lastPageTitle: 'Last page',
        showTotal: true,
        // alwaysShowAllBtns: true, // Always show next and previous button
        // withFirstAndLast: false, // Hide the going to First and Last page button
        // hideSizePerPage: true, // Hide the sizePerPage dropdown always
        // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
        // paginationTotalRenderer: customTotal,
        disablePageTitle: true,
        sizePerPageList: [{
            text: '5', value: 5
        }, {
            text: '10', value: 10
        }] // A numeric array is also available. the purpose of above example is custom the text
    };


    return (
        <>

            <Modal show={show} onHide={hideModal} size="xl">
                <Modal.Header closeButton>
                    <Modal.Title>Detalle de logs</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className='my-3'>
                        <Col>

                            <Row className='my-3'>
                                <Col xs={12}>

                                </Col>
                                <Col>
                                    <BootstrapTable
                                        bootstrap4
                                        keyField='webScrappingShopLogId'
                                        data={webScrappingLogDetails ?? []}
                                        columns={columns}
                                        pagination={paginationFactory(options)}
                                        filter={filterFactory()}
                                        // onTableChange={onHandleTableChange}
                                        hover />
                                </Col>
                            </Row>

                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => hideModal()}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
export default ModalLogsDetail;