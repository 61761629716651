import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { GetAgesRanges, GetBrands, GetShops, GetSpecies, GetWeightUnits } from '../../../services/Util.service';
import { GetProductsBrands } from '../../../services/Products.service';


const extraReducerActions = {
    fetchMasterDataAsync: createAsyncThunk(
        'util/getMaster',
        async (thunkApi) => {
            return Promise.all([
                GetBrands(),
                GetSpecies(),
                GetAgesRanges(),
                GetWeightUnits(),
                GetShops()
            ]).then(res => {
                return {
                    brands: res[0],
                    species: res[1],
                    ageRanges: res[2],
                    weightUnits: res[3],
                    shops: res[4]
                };
            })
        }
    )
}


const utilSlice = createSlice({
    name: 'util',
    initialState: {
        //These are the distincs brands names from all scrapped products
        producsBrands: [],
        //Master data
        brands: [],
        species: [],
        ageRanges: [],
        weightUnits: [],
        shops: [],
        isLoading: false,

    },
    reducers: {
        setIsLoading(state, action) {

            state.isLoading = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(extraReducerActions.fetchMasterDataAsync.fulfilled, (state, action) => {
                state.brands = action.payload.brands
                state.species = action.payload.species
                state.ageRanges = action.payload.ageRanges
                state.weightUnits = action.payload.weightUnits
                state.shops = action.payload.shops
            })
    }
})

const exports = { ...utilSlice.actions, ...extraReducerActions }
export const {
    setIsLoading,
    fetchMasterDataAsync
} = exports

export default utilSlice.reducer