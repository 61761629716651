import { Container, Nav, Navbar } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import DocpupLogo from '../../assets/img/docpup-logo.png'
import { GetLogger } from '../../utils/utils';
import { MODULES } from '../../utils/constants';
import { PrivateRoutesConfigFlat, PublicRoutesConfigFlat, RoutesConfig, RoutesConfigFlat } from '../../routes/AppRoutesConfig';
const customLogger = GetLogger(MODULES.HEADER_PAGE)

const HeaderPage = ({ session, logout }) => {

    const navigate = useNavigate();


    const doLogout = () => {
        logout()
        navigate("/")
    }

    customLogger("Renderizando HeaderPage")

    const renderAdminMenu = () => {
        if (session) {

            const adminMenu = PrivateRoutesConfigFlat.filter(x=>x.showInMenu).map((x, index) =>
            (
                <Nav.Link key={index} onClick={() => navigate(x.path)}>{x.label}</Nav.Link>
            )
            )
            // customLogger("admin menu", adminMenu)
            return adminMenu

        } else {
            return null
        }
    }

    const renderPublicMenu = () => {

        const adminMenu = PublicRoutesConfigFlat.filter(x=>x.showInMenu).map((x, index) =>
        (
            <Nav.Link key={index} onClick={() => navigate(x.path)}>{x.label}</Nav.Link>
        )
        )
        // customLogger("admin menu", adminMenu)
        return adminMenu


    }

    return (
        <>
            <Navbar expand="lg"
                variant="docpup"
            // bg="docpup-blue"
            >
                <Container>

                    <Navbar.Brand href="#" onClick={() => navigate("/")} >
                        <img style={{ width: "50px" }} src={DocpupLogo} />
                        {" "}
                        DocPup Cotiza
                    </Navbar.Brand>
                    <Nav className="me-auto text-white">
                        {/* <Nav.Link onClick={() => navigate("/")} >Inicio</Nav.Link> */}
                        {renderPublicMenu()}
                        {renderAdminMenu()}
                        {session && (<Nav.Link onClick={() => doLogout()}>Cerrar Sesion</Nav.Link>)}
                    </Nav>
                </Container>
            </Navbar>
        </>
    )
}
export default HeaderPage