
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux/es/exports';
import { Button, Card, Col, Form, Image, Row } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { GetBrands, GetAgesRanges, GetSpecies } from '../../services/Util.service';
import { fetchItemsAsync } from '../../store/features/search/search.slice';
import { setIsLoading } from '../../store/features/util/util.slice';

const SidebarFilter = () => {


    const itemsFilters = useSelector(state => state.search.itemsFilters)

    let [urlSearchParams, setUrlSearchParams] = useSearchParams();

    const { handleSubmit, register, formState: { errors }, control, setValue } = useForm({
        defaultValues: {
            itemName: null,
            itemWeight: null,
            itemWeightUnit: null,
            itemBrand: "",
            itemSpecie: "",
            itemAgeRange: "",
            orderBy: "",
            orderByType: "",
            per_page: 9,
            page: 1
        }
    })

    const brands = useSelector(state => state.util.brands)
    const species = useSelector(state => state.util.species)
    const agesRanges = useSelector(state => state.util.ageRanges)


    const dispatch = useDispatch()
    const history = useNavigate()

    const fetchItems = async (data) => {
        dispatch(setIsLoading(true))
        await dispatch(fetchItemsAsync(data))
        dispatch(setIsLoading(false))
    }
    useEffect(() => {
        if (itemsFilters) {

            if (itemsFilters.page)
                setValue("page", itemsFilters.page)

            if (itemsFilters.per_page)
                setValue("per_page", itemsFilters.per_page)

            if (itemsFilters.orderBy)
                setValue("orderBy", itemsFilters.orderBy)

            if (itemsFilters.orderByType)
                setValue("orderByType", itemsFilters.orderByType)

            if (itemsFilters.itemName)
                setValue("itemName", itemsFilters.itemName)

            if (itemsFilters.itemWeight)
                setValue("itemWeight", itemsFilters.itemWeight)

            if (itemsFilters.itemWeightUnit)
                setValue("itemWeightUnit", itemsFilters.itemWeightUnit)

            if (itemsFilters.itemBrand && itemsFilters.itemBrand !== "0")
                setValue("itemBrand", itemsFilters.itemBrand)

            if (itemsFilters.itemAgeRange && itemsFilters.itemAgeRange !== "0")
                setValue("itemAgeRange", itemsFilters.itemAgeRange)

            if (itemsFilters.itemSpecie && itemsFilters.itemSpecie !== "0")
                setValue("itemSpecie", itemsFilters.itemSpecie)


        }
    }, [itemsFilters])

    useEffect(() => {
        if (urlSearchParams && urlSearchParams.get("page")) {
            fetchItems(Object.fromEntries(urlSearchParams.entries()))
        } else {
            let baseFilters = { page: 1, per_page: 9, orderBy: 'price', orderByType: 'asc' }
            history(`?${new URLSearchParams(baseFilters)}`)
        }
    }, [urlSearchParams])

    /**
     * Aplica filtros del formulario en el "sidebar"
     * Siempre retorna a la primera página
     * @param {*} data 
     */
    const onSubmit = (data) => {

        let formData = {}

        // if (data.page)
        //     Object.assign(formData, { page: data.page })
        Object.assign(formData, { page: 1 })

        if (data.per_page)
            Object.assign(formData, { per_page: data.per_page })

        if (data.orderBy)
            Object.assign(formData, { orderBy: data.orderBy })

        if (data.orderByType)
            Object.assign(formData, { orderByType: data.orderByType })

        if (data.itemName)
            Object.assign(formData, { itemName: data.itemName })

        if (data.itemWeight)
            Object.assign(formData, { itemWeight: data.itemWeight })

        if (data.itemWeightUnit)
            Object.assign(formData, { itemWeightUnit: data.itemWeightUnit })

        if (data.itemBrand && data.itemBrand !== "0")
            Object.assign(formData, { itemBrand: data.itemBrand })

        if (data.itemAgeRange && data.itemAgeRange !== "0")
            Object.assign(formData, { itemAgeRange: data.itemAgeRange })

        if (data.itemSpecie && data.itemSpecie !== "0")
            Object.assign(formData, { itemSpecie: data.itemSpecie })

        //history(`?${new URLSearchParams(formData)}`)
        history(`?${new URLSearchParams(formData)}`)
        // fetchItems(formData)
    }



    return (
        <Card className='claymorphism-item mb-2'>
            <Card.Body>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Form.Group className="mb-3" >
                        <Form.Label>Nombre</Form.Label>
                        <Form.Control
                            type="text"
                            {
                            ...register("itemName",
                                {
                                    maxLength: 100,
                                }
                            )}
                        />
                        {errors.itemName && errors.itemName.type === 'required' && (
                            <Form.Text className="text-danger">
                                Este campo es requerido.
                            </Form.Text>
                        )}
                        {errors.itemName && errors.itemName.type === 'maxLength' && (
                            <Form.Text className="text-danger">
                                Largo máximo 100 caracteres.
                            </Form.Text>
                        )}

                    </Form.Group>
                    <Form.Group className="mb-3" >
                        <Form.Label>Especie</Form.Label>
                        <Controller
                            control={control}
                            name="itemSpecie"
                            render={({ field: { onChange, onBlur, value, ref } }) => (
                                <Form.Select
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    value={value}
                                >
                                    <option value={0}>Seleccione especie</option>
                                    {species && species.map(specie => {
                                        return (
                                            <option key={specie.speciesId} value={specie.speciesId}>{specie.species}</option>
                                        )
                                    })}
                                </Form.Select>
                            )}
                        >
                        </Controller>
                    </Form.Group>
                    <Form.Group className="mb-3" >
                        <Form.Label>Marca</Form.Label>
                        <Controller
                            control={control}
                            name="itemBrand"
                            render={({ field: { onChange, onBlur, value, ref } }) => (
                                <Form.Select
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    value={value}
                                >
                                    <option value={0}>Seleccione marca</option>
                                    {brands && brands.map(brand => {
                                        return (
                                            <option key={brand.brandId} value={brand.brandId}>{brand.name}</option>
                                        )
                                    })}
                                </Form.Select>
                            )}
                        >
                        </Controller>
                    </Form.Group>
                    <Form.Group className="mb-3" >
                        <Form.Label>Rango etario</Form.Label>
                        <Controller
                            control={control}
                            name="itemAgeRange"
                            render={({ field: { onChange, onBlur, value, ref } }) => (
                                <Form.Select
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    value={value}
                                >
                                    <option value={0}>Seleccione rango etario</option>
                                    {agesRanges && agesRanges.map(ageRange => {
                                        return (
                                            <option key={ageRange.ageRangeId} value={ageRange.ageRangeId}>{ageRange.ageRange}</option>
                                        )
                                    })}
                                </Form.Select>
                            )}
                        >
                        </Controller>
                    </Form.Group>
                    <Form.Group className="mb-3" >
                        <Form.Label>Masa</Form.Label>
                        <Form.Control
                            type="number"
                            {...register("itemWeight",
                                { required: false }
                            )}
                        />
                        {errors.itemWeight && errors.itemWeight.type === 'required' && (
                            <Form.Text className="text-danger">
                                Este campo es requerido.
                            </Form.Text>
                        )}
                    </Form.Group>

                    <Form.Group className="mb-3" >
                        <Form.Label>Unidad de masa</Form.Label>
                        <Form.Control
                            type="text"
                            {...register("itemWeightUnit",
                                { required: false, maxLength: 10 }
                            )}
                        />
                        {errors.itemWeightUnit && errors.itemWeightUnit.type === 'required' && (
                            <Form.Text className="text-danger">
                                Este campo es requerido.
                            </Form.Text>
                        )}
                        {errors.itemWeightUnit && errors.itemWeightUnit.type === 'maxLength' && (
                            <Form.Text className="text-danger">
                                Largo máximo 10 caracteres.
                            </Form.Text>
                        )}
                    </Form.Group>
                </Form>
                <button
                    className='btn btn-docpup'
                    onClick={handleSubmit(onSubmit)}>Filtrar
                </button>
                <Row>
                    <Col></Col>
                </Row>
            </Card.Body>
        </Card>


    )
}
export default SidebarFilter